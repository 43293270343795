import React, { useEffect, useRef, useState } from 'react'
import { Col, Row, Tab, Table, Tabs } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import { getAllClients } from '../../Redux/clientDuck'
import { getOrderByClient } from '../../Redux/reportDuck'
import { connect } from 'react-redux';
import moment from 'moment';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from 'react-icons/ri';
import ItemDetailByClient from './item.detail.by_client';
import { toogleLoader } from '../../Redux/alertDuck';
import ReportByClientForm from './report_by_client_form';
import ReportByClientGroup from './report_by_client_group';

const ReportByClient = ({ getAllClients, fetching_client, data, getOrderByClient, fetching_payment, data_payment, toogleLoader }) => {

    const [form, setForm] = useState({ date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD"), client_id: 0, is_detail: false })
    const [listClient, setListClient] = useState([])
    const [listReport, setListReport] = useState([])

    const tableRef = useRef(null);


    useEffect(() => {
        if (fetching_client === 2) {

            setListClient(data)
        }
    }, [fetching_client])

    useEffect(() => {
        if (fetching_payment === 2) {
            setListReport(data_payment)
            toogleLoader(false)
        }
    }, [fetching_payment])

    useEffect(() => {
        getAllClients()

    }, [])


    const updateField = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const onSearch = () => {
        toogleLoader(true)
        getOrderByClient(form)
    }

    return (
        <TemplateMenu>
            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                <Tab eventKey="home" title="Reporte por cliente">
                    <ReportByClientForm />
                </Tab>
                <Tab eventKey="profile" title="Reporte Agrupado">
                    <ReportByClientGroup />
                </Tab>
            </Tabs>
        </TemplateMenu>
    )
}
const mapsState = (props) => {

    let { client, report } = props

    return {
        fetching_client: client.fetching_client,
        data: client.clients,
        fetching_payment: report.fetching_payment,
        data_payment: report.data_payment,
    }
}

export default connect(mapsState, { getAllClients, getOrderByClient, toogleLoader })(ReportByClient);