import axios from 'axios'
import { getHeaders, getHeadersImage, validateBusiness } from '../utils';
import { logOutAction } from "./userDuck";

const API_URL = process.env.REACT_APP_API_URL
let initialState = {
    fetching_list: 0,
    fetching_create: 0,
    fetching_by_id: 0,
    fetching_change: 0,
    data: [],
    selected: {},
    errors: {},
    row: {},
    fetching_delete: 0
}

const LIST_BUSINESS = "LIST_BUSINESS"
const LIST_BUSINESS_SUCCESS = "LIST_BUSINESS_SUCCESS"
const CREATE_BUSINESS = "CREATE_BUSINESS"
const CREATE_BUSINESS_SUCCESS = "CREATE_BUSINESS_SUCCESS"
const UPDATE_BUSINESS = "UPDATE_BUSINESS"
const UPDATE_BUSINESS_SUCCESS = "UPDATE_BUSINESS_SUCCESS"
const BUSINESS_ERROR = "BUSINESS_ERROR"
const RENOVATE = "RENOVATE"
const RENOVATE_SUCCESS = "RENOVATE_SUCCESS"
const BUSINESS_BY_ID = "BUSINESS_BY_ID"
const BUSINESS_BY_ID_SUCCESS = "BUSINESS_BY_ID_SUCCESS"
const CHANGE_BUSINESS = "CHANGE_BUSINESS"
const CHANGE_BUSINESS_SUCCESS = "CHANGE_BUSINESS_SUCCESS"

export default function reducer(state = initialState, action) {
    let clients;
    switch (action.type) {
        case LIST_BUSINESS:
            return { ...state, fetching_list: 1, fetching_create: 0 }
        case LIST_BUSINESS_SUCCESS:
            // clients = action.payload
            // clients = clients.concat(state.clients)
            return { ...state, fetching_list: 2, data: action.payload, current_page: action.current_page, last_page: action.last_page }
        case CREATE_BUSINESS:
            return { ...state, fetching_create: 1, }
        case CREATE_BUSINESS_SUCCESS:
            return { ...state, fetching_create: 2 }
        case UPDATE_BUSINESS:
            return { ...state, fetching_create: 1, }
        case UPDATE_BUSINESS_SUCCESS:
            return { ...state, fetching_create: 2 }
        case BUSINESS_ERROR:
            return { ...state, fetching_create: 3, errors: action.payload }
        case RENOVATE:
            return { ...state, fetching_create: 1 }
        case RENOVATE_SUCCESS:
            return { ...state, fetching_create: 2 }
        case BUSINESS_BY_ID:
            return { ...state, fetching_by_id: 1 }
        case BUSINESS_BY_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, row: action.payload }
        case CHANGE_BUSINESS:
            return { ...state, fetching_change: 1 }
        case CHANGE_BUSINESS_SUCCESS:
            return { ...state, fetching_change: 2 }
        default:
            return state;
    }
}

export let getAllBusiness = (page) => (dispatch, getState) => {
    dispatch({
        type: LIST_BUSINESS
    })

    page = page || getState().user.current_page
    axios.get(`${API_URL}/api/business?page=${page}&limit=30`, {
        headers: getHeaders()
    })
        .then(response => {

            const { list } = getState().user

            let _data = list || []


            _data = _data.concat(response.data.results)

            dispatch({
                type: LIST_BUSINESS_SUCCESS,
                payload: response.data.results,
                current_page: 0,
            })
        })
        .catch(error => {

        });
}

export let updateBusiness = (form) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_BUSINESS
    })

    let val = validateBusiness(form, true);

    if (Object.keys(val).length > 0) {
        dispatch({
            type: BUSINESS_ERROR,
            payload: val
        })
        return;
    }

    axios.put(`${API_URL}/api/business/${form.id}`, form, {
        headers: getHeaders()
    })
        .then(response => {
            dispatch({
                type: UPDATE_BUSINESS_SUCCESS
            })
        })
        .catch(error => {
            // let { status } = error.response;

            // if (status === 401) {
            //     logOutAction()(dispatch, getState)
            // }
            // console.log("status", status)
        });
}

export let getBusinessById = (id) => (dispatch, getState) => {
    dispatch({
        type: BUSINESS_BY_ID
    })

    axios.get(`${API_URL}/api/business/${id}`, {
        headers: getHeaders()
    })
        .then(response => {
            dispatch({
                type: BUSINESS_BY_ID_SUCCESS,
                payload: response.data
            })
        })
        .catch(error => {
            // let { status } = error.response;

            // if (status === 401) {
            //     logOutAction()(dispatch, getState)
            // }
            // console.log("status", status)
        });

}

export let createOrUpdateBusiness = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_BUSINESS
    })

    let val = validateBusiness(form);

    if (Object.keys(val).length > 0) {
        dispatch({
            type: BUSINESS_ERROR,
            payload: val
        })
        return;
    }

    let _form = new FormData()
    _form.append("id", (form.id === undefined) ? '' : form.id);
    _form.append("business_name", form.business_name);
    _form.append("document", form.document);
    _form.append("dv", form.dv);
    _form.append("economic_activity", form.economic_activity);
    if (typeof form.logo !== 'string') {
        _form.append("logo", form.logo);
    }

    _form.append("phone", form.phone);
    _form.append("resolution", form.resolution);
    _form.append("address", form.address);
    _form.append("gift", form.gift);
    _form.append("slogan", form.slogan);
    _form.append("web", form.web);
    _form.append("email", form.email);

    let url = `${API_URL}/api/business`

    axios.post(url, _form, {
        headers: getHeadersImage()
    })
        .then(response => {
            dispatch({
                type: CREATE_BUSINESS_SUCCESS
            })
        })
        .catch(error => {
        });

}

export let onRenoveteBusiness = (row) => (dispatch, getState) => {
    dispatch({
        type: RENOVATE
    })

    axios.put(`${API_URL}/api/plan-renovation/${row.id}`, row, {
        headers: getHeaders()
    })
        .then(response => {
            dispatch({
                type: RENOVATE_SUCCESS
            })
        })
        .catch(error => {
            // let { status } = error.response;

            // if (status === 401) {
            //     logOutAction()(dispatch, getState)
            // }
            // console.log("status", status)
        });

}

export let onAssignBusiness = (row) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_BUSINESS
    })

    axios.post(`${API_URL}/api/business-assign`, row, {
        headers: getHeaders()
    })
        .then(response => {
            dispatch({
                type: CHANGE_BUSINESS_SUCCESS
            })
        })
        .catch(error => {
            // let { status } = error.response;

            // if (status === 401) {
            //     logOutAction()(dispatch, getState)
            // }
            // console.log("status", status)
        });

}