import React from 'react'
import TemplateMenu from '../../template/menu.template'
import { connect } from 'react-redux';

const ReviewCXC = () => {
    return (
        <TemplateMenu>
            <p>ReviewCXC</p>
        </TemplateMenu>

    )
}

const mapsState = () => {

}
export default connect(mapsState, {})(ReviewCXC);