import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { Card, Col, Row } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';
import { connect } from 'react-redux';
import { getCompany, getFEData, storeCompany, storeSoftware, storeCertificate, storeResolution } from '../../Redux/fetDuck'
import { toogleLoader } from '../../Redux/alertDuck'

const FECongigurationPage = ({ getCompany, fetching_company, data_company, getFEData, fetching_fe, data_fe,
    storeCompany, toogleLoader, fetching_company_store, storeSoftware, storeCertificate, storeResolution
}) => {
    const [formBusiness, setFormBusiness] = useState({
        business_name: '', identification_number: '', dv: '',
        type_document_identification_id: 0, address: '', phone: '',
        merchant_registration: '', email: '', api_token: ''
    })
    const [formSoftware, setFormSoftware] = useState({ id: '', pin: '' })
    const [formCert, setFormCert] = useState({ password: '' })
    const [formResolution, setFormResolution] = useState({
        type_document_id: '', prefix: '', resolution: '',
        resolution_date: '', technical_key: '', from: '', to: '', generated_to_date: '',
        date_from: '', date_to: ''
    })

    const [listTypeDocumentIdentification, setListTypeDocumentIdentification] = useState([])
    const [listTypeDocument, setListTypeDocument] = useState([])
    const [listTypeOrganization, setListTypeOrganization] = useState([])
    const [listTypeRegimes, setListTypeRegimes] = useState([])
    const [listTypeLiabilities, setListTypeLiabilities] = useState([])
    const [listDepartments, setListDepartment] = useState([])
    const [listMunicipalities, setListMunicipalities] = useState([])

    useEffect(() => {
        if (fetching_company === 2) {
            let { company, software, certificate, resolution } = data_company


            if (company !== undefined) {
                setFormBusiness(company)
                setFormSoftware(software)
                setFormCert(certificate)
                setFormResolution(resolution)

                console.log('company', company);
                let department = listDepartments.filter(d => d.id === company.department_id)

                console.log('department', department);


                if (department.length > 0) {
                    let { municipalities } = department[0]
                    setListMunicipalities(municipalities)
                }
            }


            toogleLoader(false)
        }
    }, [fetching_company])

    useEffect(() => {
        if (fetching_fe === 2) {
            getCompany()
            let { type_document_identifications, type_organizations, type_regimes, type_liabilities,
                departments, type_documents
            } = data_fe
            setListTypeDocumentIdentification(type_document_identifications)
            setListTypeOrganization(type_organizations)
            setListTypeRegimes(type_regimes)
            setListTypeLiabilities(type_liabilities)
            setListDepartment(departments)
            setListTypeDocument(type_documents)

        }
    }, [fetching_fe])

    useEffect(() => {
        if (fetching_company_store === 2) {
            toogleLoader(false)
        }
    }, [fetching_company_store])

    useEffect(() => {
        toogleLoader(true)
        getFEData()
    }, [])

    const updateInputBusiness = (e) => {
        setFormBusiness({
            ...formBusiness,
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'department_id') {
            let department = listDepartments.filter(d => d.id === parseInt(e.target.value))
            let { municipalities } = department[0]
            setListMunicipalities(municipalities)
        }
    }

    const updateInputSoftware = (e) => {
        setFormSoftware({
            ...formSoftware,
            [e.target.name]: e.target.value
        })
    }
    const updateInputCert = (e) => {
        setFormCert({
            ...formCert,
            [e.target.name]: e.target.value
        })
    }
    const updateInputResolution = (e) => {
        setFormResolution({
            ...formResolution,
            [e.target.name]: e.target.value
        })
    }

    const storeBusiness = () => {
        toogleLoader(true)
        storeCompany(formBusiness)
    }

    const storeSoftwareForm = () => {
        toogleLoader(true)
        storeSoftware(formSoftware, formBusiness.api_token)
    }

    const storeCertForm = () => {
        toogleLoader(true)
        storeCertificate(formCert, formBusiness.api_token)
    }

    const storeResolutionForm = () => {
        toogleLoader(true)
        storeResolution(formResolution, formBusiness.api_token)
    }

    return (
        <TemplateMenu>
            <Row className='my-3'>
                <Col>Configuraciones</Col>
            </Row>
            <Row>
                <Col lg={5}>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col><Card.Title>Información de la empresa</Card.Title></Col>
                                <Col className='d-flex justify-content-end'>
                                    <button className='btn btn-sm btn-success' onClick={storeBusiness}>Guardar</button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Card.Title>Empresa</Card.Title>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Razón social</label>
                                        <InputField
                                            placeholder="Razón social"
                                            value={formBusiness.business_name || ''}
                                            name="business_name"
                                            onChange={updateInputBusiness}
                                        // error={errors.business}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Registro de Comerciante</label>
                                        <InputField
                                            placeholder="Registro de Comerciante"
                                            value={formBusiness.merchant_registration || ''}
                                            name="merchant_registration"
                                            onChange={updateInputBusiness}
                                        // error={errors.business}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Tipo de Identificación</label>
                                        <select className='form-control'
                                            name='type_document_identification_id'
                                            value={formBusiness.type_document_identification_id}
                                            onChange={updateInputBusiness}>
                                            <option value={0}>Seleccione</option>
                                            {listTypeDocumentIdentification.map((a, b) => <option key={a.id} value={a.id}>{a.name}</option>)}
                                        </select>

                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Tipo de Persona</label>
                                        <select className='form-control'
                                            name='type_organization_id'
                                            value={formBusiness.type_organization_id}
                                            onChange={updateInputBusiness}>
                                            <option value={0}>Seleccione</option>
                                            {listTypeOrganization.map((a, b) => <option key={a.id} value={a.id}>{a.name}</option>)}
                                        </select>

                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Tipo de Iva</label>
                                        <select className='form-control'
                                            name='type_regime_id'
                                            value={formBusiness.type_regime_id}
                                            onChange={updateInputBusiness}>
                                            <option value={0}>Seleccione</option>
                                            {listTypeRegimes.map((a, b) => <option key={a.id} value={a.id}>{a.name}</option>)}
                                        </select>

                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Tipo de Responsabilidad</label>
                                        <select className='form-control'
                                            name='type_liability_id'
                                            value={formBusiness.type_liability_id}
                                            onChange={updateInputBusiness}>
                                            <option value={0}>Seleccione</option>
                                            {listTypeLiabilities.map((a, b) => <option key={a.id} value={a.id}>{a.name}</option>)}
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={7}>
                                    <div className="form-group">
                                        <label>Número de Identificación</label>
                                        <InputField
                                            type={"number"}
                                            placeholder="Número de Identificación"
                                            value={formBusiness.identification_number || ''}
                                            name="identification_number"
                                            onChange={updateInputBusiness}
                                        // error={errors.business}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Verificación</label>
                                        <InputField
                                            type={"number"}
                                            placeholder="Verificación"
                                            value={formBusiness.dv || ''}
                                            name="dv"
                                            onChange={updateInputBusiness}
                                        // error={errors.business}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Departamento</label>
                                        <select className='form-control'
                                            name='department_id'
                                            value={formBusiness.department_id}
                                            onChange={updateInputBusiness}>
                                            <option value={0}>Seleccione</option>
                                            {listDepartments.map((a, b) => <option key={a.id} value={a.id}>{a.name}</option>)}

                                        </select>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Ciudad</label>
                                        <select className='form-control'
                                            name='municipality_id'
                                            value={formBusiness.municipality_id}
                                            onChange={updateInputBusiness}>
                                            <option value={0}>Seleccione</option>
                                            {listMunicipalities.map((a, b) => <option key={a.id} value={a.id}>{a.name}</option>)}

                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={7}>
                                    <div className="form-group">
                                        <label>Dirección</label>
                                        <InputField
                                            placeholder="Dirección"
                                            value={formBusiness.address || ''}
                                            name="Dirección"
                                            onChange={updateInputBusiness}
                                        // error={errors.business}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Telefono</label>
                                        <InputField
                                            type={"number"}
                                            placeholder="Telefono"
                                            value={formBusiness.phone || ''}
                                            name="phone"
                                            onChange={updateInputBusiness}
                                        // error={errors.business}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={7}>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <InputField
                                            placeholder="Email"
                                            value={formBusiness.email || ''}
                                            name="email"
                                            onChange={updateInputBusiness}
                                        // error={errors.business}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header>
                                    <Row>
                                        <Col><Card.Title>Software</Card.Title></Col>
                                        <Col className='d-flex justify-content-end'>

                                            {formBusiness.api_token &&
                                                <button className='btn btn-sm btn-success' onClick={storeSoftwareForm}>Guardar</button>}

                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <label>Id</label>
                                                <InputField
                                                    placeholder="id"
                                                    value={formSoftware.id || ''}
                                                    name="id"
                                                    onChange={updateInputSoftware}
                                                // error={errors.business}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <label>Pin</label>
                                                <InputField
                                                    placeholder="pin"
                                                    value={formSoftware.pin || ''}
                                                    name="pin"
                                                    onChange={updateInputSoftware}
                                                // error={errors.business}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col>
                            <Card>
                                <Card.Header>
                                    <Row>
                                        <Col><Card.Title>Certificado</Card.Title></Col>
                                        <Col className='d-flex justify-content-end'>
                                            <button className='btn btn-sm btn-success' onClick={storeCertForm}>Guardar</button>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <label>Certificado en Codificado</label>
                                                <textarea className='form-control' rows={5}
                                                    name='certificate'
                                                    onChange={updateInputCert}
                                                ></textarea>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <label>Password</label>
                                                <InputField
                                                    placeholder="Password"
                                                    value={formCert.password || ''}
                                                    name="password"
                                                    onChange={updateInputCert}
                                                // error={errors.business}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card></Col>
                    </Row>

                </Col>
                <Col>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col><Card.Title>Resolución</Card.Title></Col>
                                <Col className='d-flex justify-content-end'>
                                    <button className='btn btn-sm btn-success' onClick={storeResolutionForm}>Guardar</button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Tipo de Documento</label>
                                        <select className='form-control'
                                            name='type_document_id'
                                            value={formResolution.type_document_id}
                                            onChange={updateInputBusiness}>
                                            <option value={0}>Seleccione</option>
                                            {listTypeDocument.map((a, b) => <option key={a.id} value={a.id}>{a.name}</option>)}

                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Prefix</label>
                                        <InputField
                                            placeholder="Password"
                                            value={formResolution.prefix || ''}
                                            name="prefix"
                                            onChange={updateInputResolution}
                                        // error={errors.business}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Resolución</label>
                                        <InputField
                                            placeholder="Resolución"
                                            value={formResolution.resolution || ''}
                                            name="resolution"
                                            onChange={updateInputResolution}
                                        // error={errors.business}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Fecha de Resolución</label>
                                        <InputField
                                            type={"date"}
                                            placeholder="Fecha de Resolución"
                                            value={formResolution.resolution_date || ''}
                                            name="resolution_date"
                                            onChange={updateInputResolution}
                                        // error={errors.business}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Llave Tecnica</label>
                                        <InputField
                                            placeholder="Llave Tecnica"
                                            value={formResolution.technical_key || ''}
                                            name="technical_key"
                                            onChange={updateInputResolution}
                                        // error={errors.business}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Desde</label>
                                        <InputField
                                            placeholder="Desde"
                                            value={formResolution.from || ''}
                                            name="from"
                                            onChange={updateInputResolution}
                                        // error={errors.business}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Hasta</label>
                                        <InputField
                                            placeholder="Hasta"
                                            value={formResolution.to || ''}
                                            name="to"
                                            onChange={updateInputResolution}
                                        // error={errors.business}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Fecha Desde</label>
                                        <InputField
                                            placeholder="Fecha Desde"
                                            value={formResolution.date_from || ''}
                                            name="date_from"
                                            onChange={updateInputResolution}
                                        // error={errors.business}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Fecha Hasta</label>
                                        <InputField
                                            placeholder="Fecha Hasta"
                                            value={formResolution.date_to || ''}
                                            name="date_to"
                                            onChange={updateInputResolution}
                                        // error={errors.business}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={6}>
                                    <div className="form-group">
                                        <label>Fecha Generada</label>
                                        <InputField
                                            placeholder="Fecha Generada"
                                            value={formResolution.generated_to_date || ''}
                                            name="generated_to_date"
                                            onChange={updateInputResolution}
                                        // error={errors.business}
                                        />
                                    </div>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </TemplateMenu>
    )
}
const mapsState = (props) => {
    let { fe } = props

    return {
        fetching_company: fe.fetching_company,
        data_company: fe.data_company,
        fetching_fe: fe.fetching_fe,
        data_fe: fe.data_fe,
        fetching_company_store: fe.fetching_company_store,
    }
}
export default connect(mapsState, {
    getCompany, getFEData, storeCompany, toogleLoader, storeSoftware,
    storeCertificate, storeResolution
})(FECongigurationPage);