import React from 'react'
import TemplateMenu from '../../template/menu.template'
import { connect } from 'react-redux'
import { Col, Row } from 'react-bootstrap'

const LicenseBusinessPage = () => {
    return (
        <TemplateMenu>
            <Row>
                <Col>Licencia</Col>
            </Row>
            <p>LicenseBusinessPage</p>
        </TemplateMenu>

    )
}

const mapsState = (props) => {
    return {

    }
}
export default connect(mapsState, {})(LicenseBusinessPage);