import React, { useEffect, useRef, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { Col, Row, Table } from 'react-bootstrap';
import { getOtherRevenues } from '../../Redux/otherRevenuesDuck'
import { connect } from 'react-redux';
import moment from 'moment';
import { formatNumber } from '../../utils';
import { getTurns } from '../../Redux/turnDuck';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from 'react-icons/ri';

const OtherRevenuesPage = ({ getOtherRevenues, fetching_list, data, getTurns, fetching_list_turn, data_turn, row }) => {
    const tableRef = useRef(null);
    const [list, setList] = useState([])
    const [form, setForm] = useState({
        date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD"), status_id: 3,
        method_payment_id: 0, concept_id: 0, deleted: false
    })
    const [listTurn, setListTurn] = useState([])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])

    useEffect(() => {

        if (fetching_list_turn === 2) {
            setListTurn(data_turn)
            getOtherRevenues()
        }
    }, [fetching_list_turn])

    useEffect(() => {
        getTurns()
    }, [])


    const updateField = (e) => {

        if (e.target.type === 'checkbox') {
            setForm({
                ...form,
                [e.target.name]: e.target.checked
            })
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value
            })
        }
    }

    return (
        <TemplateMenu>

            <Row>
                <Col>
                    <div className="form-group">
                        <label>Fecha de Inicio</label>
                        <input
                            type="date"
                            className="form-control"
                            value={form.date_init}
                            name="date_init"
                            onChange={updateField}
                        />
                    </div>
                </Col>
                <Col>
                    <div className="form-group">
                        <label>Fecha Fin</label>
                        <input
                            type="date"
                            className="form-control"
                            value={form.date_end}
                            name="date_end"
                            onChange={updateField}
                        />
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="form-group">
                        <label>Turnos</label>
                        <select className='form-control' name='turn_id' value={form.turn_id} onChange={updateField}>
                            <option value={0}>Seleccione</option>
                            {listTurn.map((d, k) => <option key={k} value={d.id}>{d.user.name} | {d.date_start} | {d.date_end}</option>)}
                        </select>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <DownloadTableExcel
                                        filename="otros_ingreso"
                                        sheet="otros_ingreso"
                                        currentTableRef={tableRef.current}>
                                        <RiFileExcel2Fill color='green' size={30} className='cursor-pointer' />
                                    </DownloadTableExcel>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table className='table-sm table-bordered table-hover' ref={tableRef} responsive>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Concepto</th>
                                        <th>Descripción</th>
                                        <th>Método de Pago</th>
                                        <th>Soporte</th>
                                        <th className='text-center'>Valor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list.map((a, b) => <tr>
                                        <td>{moment(a.registration_date).format("YYYY-MM-DD")}</td>
                                        <td>{a.description}</td>
                                        <td>{a.parameter.description}</td>
                                        <td>{a.method_payment.description}</td>
                                        <td></td>
                                        <td align='right'>{a.value_formated}</td>
                                    </tr>)}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={5}><b>TOTAL</b></td>
                                        <td align='right'><b>{formatNumber(list.reduce((a, b) => a + parseFloat(b.value), 0))}</b></td>

                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </TemplateMenu>
    )
}


const mapsState = (props) => {
    let { other_revenues, turn } = props

    return {
        fetching_list: other_revenues.fetching_list,
        data: other_revenues.data,
        turn: turn.row,
        fetching_list_turn: turn.fetching_list,
        data_turn: turn.data
    }
}
export default connect(mapsState, { getOtherRevenues, getTurns })(OtherRevenuesPage);