import React, { useRef } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';

const ModalCargaCount = ({ show, onHide, onUpload }) => {

    const text = useRef('')


    const validateData = () => {
        let content = text.current.value
        let filtered = content.split("\n")

        let list_product = []
        filtered.forEach(d => {
            d = d.split("\t")
            if (d[1] !== 'Id') {
                list_product.push({ product_id: d[1], quantity: d[11] });
            }

        })

        onUpload(list_product)
    }

    return (
        <Modal show={show} onHide={onHide} >
            <Modal.Header closeButton>
                <Modal.Title>Carga de Excel </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <textarea className='form-control' placeholder='Pega el contenido del excel' ref={text}></textarea>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col>
                        <button className='form-control btn btn-sm btn-info' onClick={validateData}>Cargar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>

    )
}

export default ModalCargaCount;