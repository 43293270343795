import moment from 'moment';
import React from 'react'
import { Table } from 'react-bootstrap';
import { formatNumber } from '../../utils';

const ItemDetailByClient = ({ item, index, is_detail }) => {

    let { detail } = item

    return (
        <>
            <tr>
                <td>
                    {index + 1}
                </td>
                <td>{item.client}</td>
                <td><a href={`${item.phone}`}>{item.phone}</a></td>
                <td>{item.category}</td>
                <td>{item.plate}</td>
                <td align='right'>{formatNumber(item.quantity)}</td>
                <td align='right'>{formatNumber(item.total)}</td>
            </tr>
        </>
    )
}

export default ItemDetailByClient;