import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import { createReference } from '../../Redux/brandModelDuck'


const initState = { reference: '', type_id: 0 };


const ModalSparePartModal = ({ show, fetching_create, onSuccess, cylinder, createReference }) => {

    const [form, setForm] = useState(initState)

    useEffect(() => {
        if (fetching_create === 2) {
            onSuccess();
            setForm(initState)
        }
    }, [fetching_create])


    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = () => {
        let _form = form
        _form.cylinder_id = cylinder.id

        createReference(_form)
    }

    return (
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Agregar Referencia a ({cylinder.capacity})</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Tipo</label>
                            <select className='form-control' value={form.type_id} name="type_id" onChange={updateInput}>
                                <option value={0}>Seleccione</option>
                                <option value={1}>Aire</option>
                                <option value={2}>Aceite</option>
                                <option value={3}>Combustible</option>
                                <option value={4}>Aire Acondicionado</option>
                                <option value={5}>Base/Sensor/Vaso</option>
                            </select>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Referencia</label>
                            <input className='form-control' name='reference' onChange={updateInput} value={form.reference} placeholder="Referencia" />
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col>
                        <button className='btn btn-sm btn-success' onClick={onSubmit}>Agregar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

const mapsState = (props) => {
    let { brandModel } = props

    return {
        fetching_create: brandModel.fetching_create
    }
}

export default connect(mapsState, { createReference })(ModalSparePartModal);