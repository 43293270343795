import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
const API_URL = process.env.REACT_APP_API_URL

const ModalPhoto = ({ show, photo, onHide }) => {
    let _photo = `${API_URL}${photo}`
    return (
        <Modal show={show} size={"lg"} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Foto Soporte</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='d-flex justify-content-center'>
                    <Col lg={4}><img src={_photo} className="img-fluid" /></Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalPhoto;