import React, { useEffect, useRef, useState } from 'react'
import TemplateMenu from '../../../template/menu.template'
import { connect } from 'react-redux';
import { getTurns } from '../../../Redux/turnDuck';
import { toogleAlert } from '../../../Redux/alertDuck'
import { Col, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { getReviewProduct } from '../../../Redux/reportDuck'
import moment from 'moment';
import ReviewProductStore from './review.product.store';
import ReviewProductOrder from './review.product.order';
import ReviewProductTotal from './review.product.total';
import ReviewProductInput from './review.product.input';

const ReviewProducts = ({ getTurns, fetching_list_turn, data_turn, toogleAlert, getReviewProduct, fetching, data }) => {

    const [listTurn, setListTurn] = useState([])
    const [listData, setListData] = useState({ quantity_cart: 0, quantity_additional: 0, quantity_inputs: 0 })
    const [form, setForm] = useState({ turn_id: 0, date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD") })
    const table = useRef(null);

    useEffect(() => {
        toogleAlert(true)
        getTurns()
    }, [])

    useEffect(() => {
        if (fetching_list_turn === 2) {
            toogleAlert(false)
            setListTurn(data_turn)
        }
    }, [fetching_list_turn])
    useEffect(() => {
        if (fetching === 2) {
            toogleAlert(false)
            setListData(data)
        }
    }, [fetching])

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSearch = () => {
        toogleAlert(true)
        getReviewProduct(form)
    }

    return (
        <TemplateMenu>
            <Row className='mt-3'>
                <Col lg={3}>
                    <div className="form-group">
                        <label>Turnos</label>
                        <select className='form-control' name='turn_id' value={form.turn_id} onChange={updateField}>
                            <option value={0}>POR RANGO DE FECHA</option>
                            {listTurn.map((d, k) => <option key={k} value={d.id}>{d.user.name} - ({d.id}) | {d.date_start} | {d.date_end}</option>)}
                        </select>
                    </div>
                </Col>
                {parseInt(form.turn_id) === 0 && <>
                    <Col lg={2}>
                        <div className="form-group">
                            <label>Fecha de Inicio</label>
                            <input
                                type="date"
                                className="form-control"
                                value={form.date_init}
                                name="date_init"
                                onChange={updateField}
                            />
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div className="form-group">
                            <label>Fecha Fin</label>
                            <input
                                type="date"
                                className="form-control"
                                value={form.date_end}
                                name="date_end"
                                onChange={updateField}
                            />
                        </div>
                    </Col>
                </>}
                <Col lg={2} className='d-flex align-items-center'>
                    <button className='btn btn-sm btn-info' onClick={onSearch}>Buscar</button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Tabs defaultActiveKey="review" id="uncontrolled-tab-example">
                        <Tab eventKey="review" title="Totales">
                            <ReviewProductTotal
                                quantity_cart={listData.quantity_cart}
                                quantity_additional={listData.quantity_additional}
                                quantity_input={listData.quantity_input}
                                total_cart={listData.total_cart}
                                total_additional={listData.total_additional}
                                total_inputs={listData.total_input} />
                        </Tab>
                        <Tab eventKey="store" title="Venta en Tienda">
                            <ReviewProductStore listData={[]} />
                        </Tab>
                        <Tab eventKey="order" title="Ventas Adicionales">
                            <ReviewProductOrder listData={[]} />
                        </Tab>
                        <Tab eventKey="input" title="Ventas Insumos">
                            <ReviewProductInput listData={[]} />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>

        </TemplateMenu>

    )
}

const mapsState = (props) => {
    let { turn, report } = props

    return {
        fetching_list_turn: turn.fetching_list,
        data_turn: turn.data,
        fetching: report.fetching,
        data: report.data,
    }
}
export default connect(mapsState, { getTurns, toogleAlert, getReviewProduct })(ReviewProducts);