import moment from "moment";
import React, { useEffect, useState } from "react";
import { formatNumber } from "../../utils";

const ItemTableCounter = ({ row, onUpdate }) => {

  const [value, setValue] = useState('')
  useEffect(() => {
    if (row.quantity !== '') {
      setValue(row.quantity)
    }
  }, [row.quantity])

  const onLeaveInput = (e) => {
    onUpdate(row, value)
  }

  return (
    <tr>
      <td>{row.name}</td>
      <td width={"20%"}>{row.measure_unit.description}</td>
      {/* <td>{row.control.description}</td> */}
      <td width={"30%"}>
        <input
          type={"number"}
          value={value}
          className="form-control input-sm"
          onChange={(e) => setValue(e.target.value)}
          onBlur={onLeaveInput}
        />
      </td>
    </tr>
  );
};

export default ItemTableCounter;
