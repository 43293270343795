import React, { useEffect, useState } from 'react'
import { Card, Col, Form, Row, Table } from 'react-bootstrap';
import TemplateMenu from '../../../template/menu.template'
import { createInventoryCount } from '../../../Redux/inventoryDuck'
import { getProductsAdmin } from '../../../Redux/productDuck'
import { connect } from 'react-redux';
import moment from 'moment';
import ItemTableCounter from '../item.table.counter';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ModalCargaCount from './modal.carga';

const CreateInventoryCountPage = ({ getInventoryCounter, fetching_list, data, createInventoryCount,
    getProductsAdmin, fetching_list_product, data_product, fetching_create }) => {

    const history = useHistory();
    const [list, setList] = useState([]);
    const [modal, setModal] = useState(false);
    const [form, setForm] = useState({
        product_id: 0,
        registration_date: moment().format("YYYY-MM-DD"),
        daily: false,
        week: false,
        month: false,
    });

    useEffect(() => {
        if (fetching_create === 2) {
            history.goBack();
        }
    }, [fetching_create])

    useEffect(() => {
        getProductsAdmin(form)
    }, [])


    useEffect(() => {
        if (fetching_list_product === 2) {
            data_product = data_product.map(d => {
                d.products.map(p => {
                    p.quantity = ''
                    return p
                })
                return d
            })

            setList(data_product);
        }
    }, [fetching_list_product]);

    const updateInput = (e) => {
        let _form = {
            ...form,
            [e.target.name]: e.target.value,
        };
        setForm(_form);
        getInventoryCounter(_form);
    };

    const updateCheckBox = (e) => {
        let _form = {
            ...form,
            [e.target.name]: e.target.checked,
        };
        setForm(_form);
        getInventoryCounter(_form);
    };

    const onUpdateItem = (row, quantity) => {
        let _data = list.map(d => {
            d.products.map(product => {
                if (product.id === row.id) {
                    product.quantity = quantity
                }

                return product
            })

            return d;
        })
        row.quantity = quantity;

        setList(_data)
    };

    const onSave = () => {
        const listProduct = []
        list.forEach(d => {
            d.products.forEach(product => {
                if (parseFloat(product.quantity) >= 0) {
                    listProduct.push(product)
                }
            })
        })
        createInventoryCount(form, listProduct)
    };

    const onUpload = (list_products) => {
        console.log('list_products', list_products);
        let _data = list.map(d => {
            d.products.map(product => {
                let filter = list_products.find(d => parseInt(d.product_id) === parseInt(product.id))
                if (filter !== undefined) {
                    product.quantity = filter.quantity
                }
                return product
            })
            return d;
        })

        setList(_data)
        setModal(false)
    }

    return (
        <TemplateMenu>
            <Row className="my-3">
                <Col>
                    <h3>Crear Conteo de Inventario</h3>
                </Col>
            </Row>
            <Row className="my-2 d-flex justify-content-center">
                <Col lg={11}>
                    <Row>
                        <Col lg={2}>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>Fecha</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={form.registration_date}
                                    name="registration_date"
                                    onChange={updateInput}
                                />
                            </Form.Group>
                        </Col>
                        {/* {listControl.map((d,k)=><Col key={k} lg={2}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>{d.description}</Form.Label>
                                <Form.Control type="checkbox" value={form.daily} checked={form.daily} name="daily" onChange={updateCheckBox}/>
                            </Form.Group>
                        </Col>)} */}

                        <Col lg={1}>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>Diario</Form.Label>
                                <Form.Control
                                    type="checkbox"
                                    value={form.daily}
                                    checked={form.daily}
                                    name="daily"
                                    onChange={updateCheckBox}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={1}>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>Semanal</Form.Label>
                                <Form.Control
                                    type="checkbox"
                                    value={form.week}
                                    checked={form.week}
                                    name="week"
                                    onChange={updateCheckBox}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={1}>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>Mensual</Form.Label>
                                <Form.Control
                                    type="checkbox"
                                    value={form.month}
                                    checked={form.month}
                                    name="month"
                                    onChange={updateCheckBox}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={1} className="d-flex align-items-center">
                            <button className="btn btn-sm btn-success" onClick={onSave}>
                                Guardar
                            </button>
                        </Col>
                        <Col lg={2} className="d-flex align-items-center">
                            <button className="btn btn-sm btn-info" onClick={() => setModal(true)}>
                                Carga Masiva
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col lg={11}>
                    {list.map((d, k) => <Row key={k}>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title>{d.name}</Card.Title>
                                    <Table className="table-bordered table-sm">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th rowSpan={2}>Producto</th>
                                                <th rowSpan={2}>Medida</th>
                                                {/* <th rowSpan={2}>Tipo de Control</th> */}
                                                <th rowSpan={2} className="text-center">
                                                    Unidades
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {d.products.length > 0 ? (
                                                d.products.map((row, k) => (
                                                    <ItemTableCounter row={row} key={k} onUpdate={onUpdateItem} />
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={12} align="center">
                                                        No se encontraron registros
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>)}

                </Col>
            </Row>
            <ModalCargaCount show={modal} onHide={() => setModal(false)} onUpload={onUpload} />
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { inventory, product } = props;

    return {
        fetching_list: inventory.fetching_list,
        fetching_create: inventory.fetching_create,
        fetching_list_product: product.fetching_list,
        data_product: product.data,
        data: inventory.data,
    }
}

export default connect(mapsState, { createInventoryCount, getProductsAdmin })(CreateInventoryCountPage);