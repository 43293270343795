
import moment from 'moment'
import { getHeaders } from '../utils'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching_list: 0,
    fetching_update: 0,
    fetching_create: 0,
    fetching_by_id: 0,
    fetching_end: 0,
    fetching_validate: 0,
    fetching_detail: 0,
    fetching_delete: 0,
    fetching_confirm: 0,
    data: [],
    data_detail: [],
    errors: {},
    row: {}
}

const LIST_INVENTORY = "LIST_INVENTORY"
const LIST_INVENTORY_SUCCESS = "LIST_INVENTORY_SUCCESS"
const PRODUCTS_INVENTORY_INITIAL = "PRODUCTS_INVENTORY_INITIAL"
const PRODUCTS_INVENTORY_INITIAL_SUCCESS = "PRODUCTS_INVENTORY_INITIAL_SUCCESS"
const CREATE_INVENTORY = "CREATE_INVENTORY"
const CREATE_INVENTORY_SUCCESS = "CREATE_INVENTORY_SUCCESS"
const DELETE_INVENTORY_BY_ID = "DELETE_INVENTORY_BY_ID"
const DELETE_INVENTORY_BY_ID_SUCCESS = "DELETE_INVENTORY_BY_ID_SUCCESS"
let INVENTORY_BY_ID = "INVENTORY_BY_ID";
let INVENTORY_BY_ID_SUCCESS = "INVENTORY_BY_ID_SUCCESS";
let UPDATE_COUNT = "UPDATE_COUNT";
let UPDATE_COUNT_SUCCESS = "UPDATE_COUNT_SUCCESS";
let CREATE_INVENTORY_COUNT = "CREATE_INVENTORY_COUNT";
let CREATE_INVENTORY_COUNT_SUCCCESS = "CREATE_INVENTORY_COUNT_SUCCCESS"
let END_INVENTORY = "END_INVENTORY";
let END_INVENTORY_SUCCESS = "END_INVENTORY_SUCCESS";
let VALIDATE_INVENTORY = "VALIDATE_INVENTORY";
let VALIDATE_INVENTORY_SUCCESS = "VALIDATE_INVENTORY_SUCCESS";
let LIST_HISTORY_DETAIL = "LIST_HISTORY_DETAIL";
let LIST_HISTORY_DETAIL_SUCCESS = "LIST_HISTORY_DETAIL_SUCCESS";
let CONFIRM_COUNT = "CONFIRM_COUNT";
let CONFIRM_COUNT_SUCCESS = "CONFIRM_COUNT_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_INVENTORY:
            return { ...state, fetching_list: 1, fetching_create: 0 }
        case LIST_INVENTORY_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload, fetching_create: 0, fetching_confirm: 0, fetching_delete: 0 }
        case PRODUCTS_INVENTORY_INITIAL:
            return { ...state, fetching_update: 1 }
        case PRODUCTS_INVENTORY_INITIAL_SUCCESS:
            return { ...state, fetching_update: 2 }
        case CREATE_INVENTORY:
            return { ...state, fetching_create: 1 }
        case CREATE_INVENTORY_SUCCESS:
            return { ...state, fetching_create: 2 }
        case INVENTORY_BY_ID:
            return { ...state, fetching_by_id: 1 }
        case INVENTORY_BY_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, row: action.payload }
        case END_INVENTORY:
            return { ...state, fetching_end: 1 }
        case END_INVENTORY_SUCCESS:
            return { ...state, fetching_end: 2, data: action.payload }
        case VALIDATE_INVENTORY:
            return { ...state, fetching_validate: 1 }
        case VALIDATE_INVENTORY_SUCCESS:
            return { ...state, fetching_validate: 2 }
        case LIST_HISTORY_DETAIL:
            return { ...state, fetching_detail: 1 }
        case LIST_HISTORY_DETAIL_SUCCESS:
            return { ...state, fetching_detail: 2, data_detail: action.payload }
        case CREATE_INVENTORY_COUNT:
            return { ...state, fetching_create: 1 }
        case CREATE_INVENTORY_COUNT_SUCCCESS:
            return { ...state, fetching_create: 2 }
        case DELETE_INVENTORY_BY_ID:
            return { ...state, fetching_delete: 1 }
        case DELETE_INVENTORY_BY_ID_SUCCESS:
            return { ...state, fetching_delete: 2 }
        case UPDATE_COUNT:
            return { ...state, fetching_update: 1 }
        case UPDATE_COUNT_SUCCESS:
            return { ...state, fetching_update: 2 }
        case CONFIRM_COUNT:
            return { ...state, fetching_confirm: 1 }
        case CONFIRM_COUNT_SUCCESS:
            return { ...state, fetching_confirm: 2 }
        default:
            return state;
    }
}

export let getListInventory = (form) => (dispatch, getState) => {
    dispatch({
        type: LIST_INVENTORY
    })

    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))
    let branch = JSON.parse(localStorage.getItem("branch"))

    let url = `${API_URL}/api/inventory?branch=${branch.id}`;


    if (form !== undefined) {
        if (parseInt(form.product_id) !== 0) {
            url += `&product_id=${form.product_id}`;
        }
    }

    url += `&date_init=${date_init}&date_end=${date_end}`;

    if (getState().user.loggedIn) {
        fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: LIST_INVENTORY_SUCCESS,
                payload: resp.results,
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let getListInventoryAvailable = () => (dispatch, getState) => {
    dispatch({
        type: LIST_INVENTORY
    })

    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))
    let branch = JSON.parse(localStorage.getItem("branch"))

    let url = `${API_URL}/api/inventory-available?branch=${branch.id}`;

    url += `&date_init=${date_init}&date_end=${date_end}`;

    if (getState().user.loggedIn) {
        fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: LIST_INVENTORY_SUCCESS,
                payload: resp.results,
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let updateInventoryInitital = (row) => (dispatch, getState) => {
    dispatch({
        type: PRODUCTS_INVENTORY_INITIAL,
    });

    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))

    let url = `${API_URL}/api/products-initial/${row.id}?date_init=${date_init}&date_end=${date_end}`;
    let branch = JSON.parse(localStorage.branch)

    row.branch_id = branch.id

    fetch(url, {
        method: "PUT",
        body: JSON.stringify(row),
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: PRODUCTS_INVENTORY_INITIAL_SUCCESS,
            payload: resp.results
        });

    })
}

export let onCreateInventory = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_INVENTORY,
    });
    let url = `${API_URL}/api/inventory`;


    let branch = JSON.parse(localStorage.branch)

    form.branch_id = branch.id

    fetch(url, {
        method: "POST",
        body: JSON.stringify(form),
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: CREATE_INVENTORY_SUCCESS,
        })

    })
}

export let getInventoryLowDown = (form) => (dispatch, getState) => {
    dispatch({
        type: LIST_INVENTORY,
    });

    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
    let branch = JSON.parse(localStorage.getItem("branch"));

    let url = `${API_URL}/api/inventory-counter-lowdown?date_init=${date_init}&date_end=${date_end}&branch=${branch.id}`;

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: LIST_INVENTORY_SUCCESS,
            payload: resp.results
        })

    })
}

export let onCreateLowDown = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_INVENTORY,
    });
    let url = `${API_URL}/api/inventory-counter-lowdown`;


    let branch = JSON.parse(localStorage.branch)

    form.branch_id = branch.id

    fetch(url, {
        method: "POST",
        body: JSON.stringify(form),
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: CREATE_INVENTORY_SUCCESS,
        })

    })
}
export let onEditLowDown = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_INVENTORY,
    });
    let url = `${API_URL}/api/inventory-counter-lowdown/${form.id}`;


    let branch = JSON.parse(localStorage.branch)

    form.branch_id = branch.id

    fetch(url, {
        method: "PUT",
        body: JSON.stringify(form),
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: CREATE_INVENTORY_SUCCESS,
        })

    })
}

export let onDeleteLowDown = (id) => (dispatch, getState) => {
    dispatch({
        type: CREATE_INVENTORY,
    });
    let url = `${API_URL}/api/inventory-counter-lowdown/${id}`;


    fetch(url, {
        method: "DELETE",
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: CREATE_INVENTORY_SUCCESS,
        })

    })
}

export let getInventoryLowDownByProduct = (form) => (dispatch, getState) => {
    dispatch({
        type: INVENTORY_BY_ID,
    });

    let url = `${API_URL}/api/inventory-counter-lowdown/${form.product_id}?registration_date=${form.registration_date}`;

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: INVENTORY_BY_ID_SUCCESS,
            payload: resp
        })

    })
}


export let createInventoryCount = (form, products) => (dispatch, getState) => {
    dispatch({
        type: CREATE_INVENTORY_COUNT,
    });
    let url = `${API_URL}/api/inventory-count`;

    let branch = JSON.parse(localStorage.branch);

    form.branch_id = branch.id;
    form.products = products;

    fetch(url, {
        method: "POST",
        body: JSON.stringify(form),
        headers: getHeaders(),
    })
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch({
                type: CREATE_INVENTORY_COUNT_SUCCCESS,
            });
        });
};

export let getInventoryCount = (form) => (dispatch, getState) => {
    dispatch({
        type: LIST_INVENTORY,
    });

    let branch = JSON.parse(localStorage.getItem("branch"));

    let url = `${API_URL}/api/inventory-count?registration_date=${form.registration_date}&product_id=${form.product_id}&daily=${form.daily}&week=${form.week}&month=${form.month}&branch=${branch.id}`;

    fetch(url, {
        headers: getHeaders(),
    })
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch({
                type: LIST_INVENTORY_SUCCESS,
                payload: resp.results,
            });
        });
};

export let deleteInventoryCountById = (id) => (dispatch, getState) => {
    dispatch({
        type: DELETE_INVENTORY_BY_ID,
    });

    let url = `${API_URL}/api/inventory-count/${id}`;

    fetch(url, {
        method: "DELETE",
        headers: getHeaders(),
    })
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch({
                type: DELETE_INVENTORY_BY_ID_SUCCESS,
                payload: resp,
            });
        });
};
export let confirmInventoryCountById = (id) => (dispatch, getState) => {
    dispatch({
        type: CONFIRM_COUNT,
    });

    const { date_init, date_end } = JSON.parse(localStorage.getItem("date"))
    const branch = JSON.parse(localStorage.getItem("branch"))

    let url = `${API_URL}/api/inventory-count-confirm/${id}?date_init=${date_init}&date_end=${date_end}&branch=${branch.id}`;

    fetch(url, {
        method: "PUT",
        headers: getHeaders(),
    })
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch({
                type: CONFIRM_COUNT_SUCCESS,
            });
        });
};

export let getInventoryCountById = (id) => (dispatch, getState) => {
    dispatch({
        type: INVENTORY_BY_ID,
    });


    let url = `${API_URL}/api/inventory-count/${id}`;

    fetch(url, {
        headers: getHeaders(),
    })
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch({
                type: INVENTORY_BY_ID_SUCCESS,
                payload: resp,
            });
        });
};

export let updateInventoryCountById = (id, quantity) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_COUNT,
    });

    let url = `${API_URL}/api/inventory-count/${id}`;

    fetch(url, {
        method: "PUT",
        body: JSON.stringify({ quantity }),
        headers: getHeaders(),
    })
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch({
                type: UPDATE_COUNT_SUCCESS,
            });
        });
};

export let getEndInventory = () => (dispatch, getState) => {
    dispatch({
        type: END_INVENTORY,
    });

    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
    let branch = JSON.parse(localStorage.getItem("branch"));


    let url = `${API_URL}/api/inventory-end-month?date_init=${date_init}&date_end=${date_end}&branch=${branch.id}`;

    fetch(url, {
        headers: getHeaders(),
    })
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch({
                type: END_INVENTORY_SUCCESS,
                payload: resp,
            });
        });
};

export let onValidateInventory = () => (dispatch, getState) => {
    dispatch({
        type: VALIDATE_INVENTORY,
    });

    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
    let branch = JSON.parse(localStorage.getItem("branch"));

    let url = `${API_URL}/api/inventory-rebuild?date_init=${date_init}`;

    fetch(url, {
        headers: getHeaders(),
    })
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch({
                type: VALIDATE_INVENTORY_SUCCESS,
            });
        });
};

export let getInventoryByDetail = (product_id) => (dispatch, getState) => {
    dispatch({
        type: LIST_HISTORY_DETAIL,
    });

    let branch = JSON.parse(localStorage.getItem("branch"))
    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))

    let url = `${API_URL}/api/inventory-available-detail?branch=${branch.id}&date_init=${date_init}&date_end=${date_end}&product_id=${product_id}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "get",
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            if (resp.ok === false) {
                // dispatch({
                //     type: CREATE_CLIENT_ERROR,
                //     payload: resp.errors
                // });
            } else {
                dispatch({
                    type: LIST_HISTORY_DETAIL_SUCCESS,
                    payload: resp
                });
            }
        })
    } else {
        console.log("no Ingreso");
    }
};

