import React, { useEffect, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import { getDetailing, onCreateDetailing, onDeleteDetailing, onEditDetailing } from '../../Redux/detailingDuck'
import { connect } from 'react-redux';
import ModalDetailing from './modal.detailing';
import { argbToHex } from '../../utils';


const DetailingPage = ({ getDetailing, onCreateDetailing, fetching_list, data, fetching_create, onDeleteDetailing, onEditDetailing, errors }) => {

    const [listValoration, setListValoration] = useState([])
    const [listInventory, setListInventory] = useState([])
    const [modalForm, setModalForm] = useState({ modal: false, title: '', type: 0 })
    const [formEdit, setFormEdit] = useState({})

    useEffect(() => {
        getDetailing()
    }, [])

    useEffect(() => {
        if (fetching_list === 2) {
            setListValoration(data.filter(d => d.type_element === 1));
            setListInventory(data.filter(d => d.type_element === 2));
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_create === 2) {
            setModalForm({ ...modalForm, modal: false, title: '' })
            getDetailing()
        }
    }, [fetching_create])

    const onDelete = (item) => {
        if (window.confirm("¿Deseas Eliminar el Registro?")) {
            onDeleteDetailing(item.id)
        }
    }

    const onOpenModal = (title, type) => {
        setModalForm({
            modal: true,
            title,
            type
        })
    }

    const onEdit = (item) => {
        setFormEdit(item)
        let title = item.type_element === 1 ? 'Valoración' : 'Inventario';
        setModalForm({
            modal: true,
            title,
            type: item.type_element
        });
    }

    return (
        <TemplateMenu>
            <Row className='mt-5 d-flex justify-content-center'>
                <Col lg={8}>
                    <Row>
                        <Col>
                            <Row>
                                <Col><h4 className='text-center'>Valoración</h4></Col>
                                <Col><button className='btn btn-sm btn-info' onClick={() => onOpenModal("Valoración", 1)}>Agregar</button></Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table className='table table-sm table-bordered'>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th>CÓDIGO</th>
                                                <th>DESCRIPCION</th>
                                                <th>COLOR</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listValoration.map(d => <tr key={d.id}>
                                                <td>{d.code}</td>
                                                <td>{d.description}</td>
                                                <td align='center'>
                                                    <div className='circle-detailing' style={{ backgroundColor: argbToHex(d.color).rgba }}></div>
                                                </td>
                                                <td>
                                                    <button className='btn btn-sm btn-info mx-2' onClick={() => onEdit(d)}>Editar</button>
                                                    <button className='btn btn-sm btn-danger' onClick={() => onDelete(d)}>X</button>
                                                </td>
                                            </tr>)}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col><h4 className='text-center'>Inventario</h4></Col>
                                <Col><button className='btn btn-sm btn-info' onClick={() => onOpenModal("Inventario", 2)}>Agregar</button></Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table className='table table-sm table-bordered'>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th>DESCRIPCION</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>{listInventory.map(d => <tr key={d.id}>
                                            <td>{d.description}</td>
                                            <td>
                                                <button className='btn btn-sm btn-info mx-2' onClick={() => onEdit(d)}>Editar</button>
                                                <button className='btn btn-sm btn-danger' onClick={() => onDelete(d)}>X</button></td>
                                        </tr>)}</tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>

            </Row>

            <ModalDetailing
                modalForm={modalForm}
                formEdit={formEdit}
                onHide={() => setModalForm({ ...modalForm, modal: false })}
                onCreate={onCreateDetailing}
                onEdit={onEditDetailing}
                errors={errors}
            />
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { detailing } = props
    return {
        fetching_list: detailing.fetching_list,
        data: detailing.data,
        fetching_create: detailing.fetching_create,
        errors: detailing.errors
    }
}

export default connect(mapsState, { getDetailing, onCreateDetailing, onDeleteDetailing, onEditDetailing })(DetailingPage);