import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Container } from 'react-bootstrap';
import { connect } from 'react-redux';

import { getMenu } from '../../Redux/menuDuck'
import { getReview } from '../../Redux/reportDuck'
import { getCategoryProduct } from '../../Redux/categoryDuck'
import { getTurnActive } from '../../Redux/turnDuck'
import { formatNumber } from '../../utils'
import { Chart as ChartJs, LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend, Filler, ArcElement } from 'chart.js'
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import { embedDashboard } from "@superset-ui/embedded-sdk";

import TemplateMenu from '../../template/menu.template'

import './index.css'

ChartJs.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend, Filler
)



const data2 = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

const data_donut = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

const options = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
};


const data_linear = {
    labels: ['1', '2', '3', '4', '5', '6'],
    datasets: [
        {
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            fill: false,
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgba(255, 99, 132, 0.2)',
        },
    ],
};

const options_linear = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
};

const colors = [
    "bg-info",
    "bg-dark",
    "bg-success"
]


const Home = ({ getMenu, getReview, fetching, data, getCategoryProduct, fetching_category, data_category, getTurnActive }) => {

    const [category, setCategory] = useState([])
    const [service, setService] = useState([])
    const [sales, setSales] = useState([])
    const [listReport, setListReport] = useState([])
    const [listCategory, setListCategory] = useState([])

    useEffect(() => {
        getMenu()
        // getReview()
        getCategoryProduct()
        getTurnActive();
        const embed = async () => {
            await embedDashboard({
                id: "96ba5f3c-c117-444d-8a9e-ae3e33c62a0d", // given by the Superset embedding UI
                supersetDomain: "http://localhost:8088",
                mountPoint: document.getElementById("super"), // any html element that can contain an iframe
                fetchGuestToken: () => fetchGuestTokenFromBackend(),
                dashboardUiConfig: { hideTitle: true }, // dashboard UI config: hideTitle, hideTab, hideChartControls (optional)
            });
        }

        if (document.getElementById("super")) {
            embed()
        }

    }, [])


    const fetchGuestTokenFromBackend = () => {
        return "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7Imxhc3RfbmFtZSI6IlgyIEFwcCIsInVzZXJuYW1lIjoiYWRtaW4iLCJmaXJzdF9uYW1lIjoiWDIgQXBwIn0sInJlc291cmNlcyI6W3sidHlwZSI6ImRhc2hib2FyZCIsImlkIjoiOTZiYTVmM2MtYzExNy00NDRkLThhOWUtYWUzZTMzYzYyYTBkIn1dLCJybHNfcnVsZXMiOltdLCJpYXQiOjE3MjU5MjUwNDcuNTYyMjc2OCwiZXhwIjoxNzI1OTI1MzQ3LjU2MjI3NjgsImF1ZCI6Imh0dHA6Ly8wLjAuMC4wOjgwODAvIiwidHlwZSI6Imd1ZXN0In0.MrIIzF7KMo2Pw-WmPR4STlV1DgNztw0Z62PteT9tmlU";
    }

    useEffect(() => {
        if (fetching_category === 2) {
            setListCategory(data_category)
        }
    }, [fetching_category])

    useEffect(() => {

        if (fetching === 2) {

            console.log('data', data);

            let { data_category, data_services, data_sales } = data
            // setCategory({
            //     labels: data_category.label,
            //     datasets: [
            //         {
            //             label: 'Ventas por Categorias',
            //             data: data_category.data,
            //             backgroundColor: [
            //                 'rgba(255, 99, 132, 0.2)',
            //                 'rgba(54, 162, 235, 0.2)',
            //                 'rgba(255, 206, 86, 0.2)',
            //                 'rgba(75, 192, 192, 0.2)',
            //                 'rgba(153, 102, 255, 0.2)',
            //                 'rgba(255, 159, 64, 0.2)',
            //             ],
            //             borderColor: [
            //                 'rgba(255, 99, 132, 1)',
            //                 'rgba(54, 162, 235, 1)',
            //                 'rgba(255, 206, 86, 1)',
            //                 'rgba(75, 192, 192, 1)',
            //                 'rgba(153, 102, 255, 1)',
            //                 'rgba(255, 159, 64, 1)',
            //             ],
            //             borderWidth: 1,
            //         },
            //     ],
            // });

            // setService({
            //     labels: data_services.label,
            //     datasets: [
            //         {
            //             label: 'Ventas por Servicios',
            //             data: data_services.data,
            //             backgroundColor: [
            //                 'rgba(255, 99, 132, 0.2)',
            //                 'rgba(54, 162, 235, 0.2)',
            //                 'rgba(255, 206, 86, 0.2)',
            //                 'rgba(75, 192, 192, 0.2)',
            //                 'rgba(153, 102, 255, 0.2)',
            //                 'rgba(255, 159, 64, 0.2)',
            //             ],
            //             borderColor: [
            //                 'rgba(255, 99, 132, 1)',
            //                 'rgba(54, 162, 235, 1)',
            //                 'rgba(255, 206, 86, 1)',
            //                 'rgba(75, 192, 192, 1)',
            //                 'rgba(153, 102, 255, 1)',
            //                 'rgba(255, 159, 64, 1)',
            //             ],
            //             borderWidth: 1,
            //         },
            //     ],
            // })

            // setSales({
            //     labels: data_sales.label,
            //     datasets: [
            //         {
            //             label: '# of Votes',
            //             data: data_sales.data,
            //             // fill: false,
            //             backgroundColor: 'rgb(255, 99, 132)',
            //             borderColor: 'rgba(255, 99, 132, 0.2)',
            //         },
            //     ],
            // })
        }

    }, [fetching])


    return (
        <TemplateMenu>
            <Row>
                <Col lg={5}>
                    <Row>
                        {listReport.map((row, key) => (
                            <Col key={key}>
                                <Card className={colors[key]}>
                                    <Card.Body>
                                        <Row>
                                            <Col className="text-white text-center font-weight-bold">{row.status}</Col>
                                        </Row>
                                        <Row>
                                            <Col className="text-white text-center">
                                                <h1>{row.count}</h1>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="text-white text-center">
                                                <h3>${row.total}</h3>
                                            </Col>
                                        </Row>

                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        <Col lg={3} id="super">

                        </Col>
                    </Row>
                    <Row className="pl-4 w-100 py-4 mt-5">
                        <Col className="">
                            <Card className="card-home">
                                <Card.Body>
                                    <Row>
                                        <Col className="text-center">Ventas por Servicios</Col>
                                    </Row>

                                    <Row className="d-flex justify-content-center h-100">
                                        <Col>
                                            {Object.keys(service).length > 0 && <Bar data={service} options={options} />}

                                        </Col>
                                    </Row>


                                </Card.Body>
                            </Card>

                        </Col>
                        <Col className="">
                            <Card className="card-home">
                                <Card.Body>
                                    <Row>
                                        <Col className="text-center">Venta por Categorias</Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {Object.keys(category).length > 0 && <Doughnut data={category} />}

                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                        </Col>
                        <Col >
                            <Card className="card-home">
                                <Card.Body>
                                    <Row>
                                        <Col className="text-center">Ventas por Días</Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {Object.keys(sales).length > 0 && <Line data={sales} options={options_linear} />}

                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                        </Col>
                        {/* <Col className="">
                            <Card className="card-home">
                                <Card.Body>
                                    <Row>
                                        <Col className="text-center">Ciudadores</Col>
                                    </Row>
                                    <Row className="d-flex justify-content-center h-100">
                                        <Col>
                                            <Bar data={data2} options={options} />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                        </Col> */}
                    </Row>
                </Col>
                <Col>
                    <Row className='mt-2'>
                        <Col className='text-center'><h3>Catalogo de Productos</h3></Col>
                    </Row>
                    <Row className='mt-2'>
                        {listCategory.map(c => <Col lg={3} className="mt-2">
                            <Card className='cursor-pointer'>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <img src={c.image} className="img-fluid" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='text-center'>{c.name}</Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>)}

                    </Row>
                </Col>
            </Row>

        </TemplateMenu >
    )
}

const mapsState = (props) => {
    let { category, report } = props


    return {
        data: report.data,
        fetching: report.fetching,
        fetching_category: category.fetching,
        data_category: category.data,
    }
}

export default connect(mapsState, { getMenu, getReview, getCategoryProduct, getTurnActive })(Home);