import moment from 'moment';
import React, { useState } from 'react'
import { Col, Container, Modal, Row, Table } from 'react-bootstrap';
import { formatNumber } from '../../utils';
import InputField from '../Components/Widgets/InputField';
import ItemTableChangePercent from './item.table.change.percent';

const ModalChange = ({ show, data, onSuccess, onHide, days }) => {
    console.log('data', data);

    let { orders, expenses } = data || {}
    const { detail } = orders
    const [percent, setPercent] = useState(0)

    const onSuccessPre = () => {
        data.percent = percent
        onSuccess(data)
    }

    return (
        <Modal show={show} size={"lg"} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{data.name}, {data.days_worked} de {days} días Trabajados</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col className='text-center'><h5>Servicios Realizados</h5></Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Porcentaje</label>
                                <InputField
                                    type={"number"}
                                    placeholder="Agrega los nombres"
                                    value={percent}
                                    name="name"
                                    onChange={(e) => setPercent(e.target.value)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table className='table-sm table-bordered'>

                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Categoria</th>
                                        <th>Producto</th>
                                        <th>Precio</th>
                                        <th>Porcentaje</th>
                                        <th>SubTotal</th>
                                        <th>Porcentaje Nuevo</th>
                                        <th>SubTotal Nuevo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {detail.length > 0 && detail.map((d, k) => <ItemTableChangePercent percent={percent} d={d} key={k} />)}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={3}>Total</th>
                                        <td align='right'><b>{formatNumber(orders.subtotal_real)}</b></td>
                                        <th></th>
                                        <td align='right'><b>{formatNumber(orders.payment_worker_real)}</b></td>
                                        <th></th>

                                        <td align='right'><b>{formatNumber(orders.subtotal_real * (percent / 100))}</b></td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col><b>TOTAL A PAGAR</b></Col>
                        <Col className='text-right'><b>{formatNumber(orders.subtotal_real * (percent / 100))}</b></Col>
                    </Row>

                </Container>

            </Modal.Body>
            <Modal.Footer>
                <Row className='d-flex justify-content-end'>
                    <Col>
                        <button className='btn btn-sm btn-success' onClick={() => onSuccessPre(data)}>Cambiar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalChange;