import React from 'react'
import { formatNumber } from '../../utils';

const ItemDetailProduct = ({ item }) => {
    let { product } = item

    return (
        <tr>
            <td>{product.name}</td>
            <td align='right'>{item.amount}</td>
            <td align='right'>{item.price_formated}</td>
            <td align='right'>{formatNumber(item.amount * item.price)}</td>
        </tr>
    )
}

export default ItemDetailProduct;