import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getListPayroll, onDeletePayroll } from '../../Redux/payrollDuck'
import { getOperators, getAdministrators } from '../../Redux/userDuck'

import { formatNumber } from '../../utils';
import moment from 'moment';
import { FaFilePdf, FaTrash } from "react-icons/fa";
import { getTurns } from '../../Redux/turnDuck';

const PayrollPage = ({ getListPayroll, fetching_list, data, onDeletePayroll, fetching_delete, getTurns,
    fetching_list_turn, data_turn, fetching_operator, data_operator, getOperators
}) => {
    const [list, setList] = useState([])
    const [listTurn, setListTurn] = useState([])
    const [form, setForm] = useState({
        turn_id: 0, date_init: moment().format("YYYY-MM-01"),
        date_end: moment().format("YYYY-MM-DD"),
        operator_id: 0
    })

    const [listOperator, setListOperator] = useState([]);

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_operator === 2) {
            setListOperator(data_operator)
        }
    }, [fetching_operator])


    useEffect(() => {
        if (fetching_delete === 2) {
            getListPayroll(form)
        }
    }, [fetching_delete])

    useEffect(() => {
        getTurns()
        getOperators();
    }, [])

    useEffect(() => {
        if (fetching_list_turn === 2) {
            setListTurn(data_turn)
        }
    }, [fetching_list_turn])

    const onDeleteRow = (row) => {
        if (window.confirm(`¿Realmente deseas Borrar el Registro?`)) {
            onDeletePayroll(row.id)
        }
    }

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSearch = () => {
        getListPayroll(form)
    }

    return (
        <TemplateMenu>
            <Row className='my-2'>
                <Col lg={1} className='d-flex justify-content-center align-items-center'>
                    <h3>Nomina</h3>
                </Col>
                <Col lg={1} className='d-flex justify-content-center align-items-center'>
                    <Link to="/nomina-create" className="btn btn-sm btn-info">Crear Nuevo</Link>
                </Col>
                <Col>
                    <div className="form-group">
                        <label>Trabajadores</label>
                        <select className='form-control' name='operator_id' value={form.operator_id} onChange={updateField}>
                            <option value={0}>Seleccione</option>
                            {listOperator.map((d, k) => <option key={k} value={d.id}>{d.name}</option>)}

                        </select>
                    </div>
                </Col>

                <Col lg={3}>
                    <div className="form-group">
                        <label>Turnos</label>
                        <select className='form-control' name='turn_id' value={form.turn_id} onChange={updateField}>
                            <option value={0}>Rango de Fecha</option>
                            {listTurn.map((d, k) => <option key={k} value={d.id}>({d.id}){d.user.name} | {d.date_start} | {d.date_end}</option>)}
                        </select>
                    </div>
                </Col>
                {parseInt(form.turn_id) === 0 && <>
                    <Col lg={2}>
                        <div className="form-group">
                            <label>Fecha de Inicio</label>
                            <input
                                type="date"
                                className="form-control"
                                value={form.date_init}
                                name="date_init"
                                onChange={updateField}
                            />
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div className="form-group">
                            <label>Fecha Fin</label>
                            <input
                                type="date"
                                className="form-control"
                                value={form.date_end}
                                name="date_end"
                                onChange={updateField}
                            />
                        </div>
                    </Col>
                </>}
                <Col className='d-flex justify-content-center align-items-center' lg={1}>
                    <button onClick={onSearch} className="btn btn-sm btn-success">Buscar</button>

                </Col>
            </Row>
            <Row>
                <Col>
                    <Table className='table table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Fecha Creación</th>
                                <th>Rango</th>
                                <th>Dìas Trabajados</th>
                                <th>Trabajador</th>
                                <th>Salario</th>
                                <th>PDF</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.length > 0 ? list.map((d, k) => <tr>
                                <td>{moment(d.createdAt).format("YYYY-MM-DD")}</td>
                                <td>{d.date_init}/{d.date_end}</td>
                                <td>{d.days}</td>
                                <td>{d.worker.name}</td>
                                <td align='right'>{formatNumber(
                                    parseFloat(d.salary) + parseFloat(d.bonification)
                                    + parseFloat(d.tip) - parseFloat(d.supplies)
                                    - parseFloat(d.advances))}</td>
                                <td>
                                    <a href={`${process.env.REACT_APP_API_URL}/api/payroll-view-pdf/${d.id}`} className='mx-2' target='_blank'>
                                        <FaFilePdf size={25} /></a>
                                    <FaTrash size={25} onClick={() => onDeleteRow(d)} />

                                </td>
                            </tr>) : <tr><td colSpan={5} align='center'>Sin Datos</td></tr>}

                        </tbody>
                    </Table>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { payroll, turn, user } = props
    return {
        fetching_list: payroll.fetching_list,
        fetching_delete: payroll.fetching_delete,
        data: payroll.data,
        fetching_list_turn: turn.fetching_list,
        data_turn: turn.data,
        fetching_operator: user.fetching_operator,
        data_operator: user.data_operator,
    }
}

export default connect(mapsState, { getListPayroll, onDeletePayroll, getTurns, getOperators })(PayrollPage);