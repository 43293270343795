import React, { useState } from 'react';
import modify from '../../Images/modify.svg'
import crown from '../../Images/crown.png'
import { Link } from 'react-router-dom';

const RowTableClient = ({ item, index, onEdit, refs }) => {
  let _index = index + 1

  let reference = (_index % 20 == 0) ? refs : null


  let { addresses } = item || []
  const [selected, setSelected] = useState({})

  const [data, setData] = useState([])

  const showAddress = () => {
    if (item.id === selected.id) {
      setSelected({})
    } else {
      setSelected(item)
    }
  }

  const printAddress = (row) => {

    return (
      <tr key={item.id} className="p-0 mx-0 border-bottom active_list_reservation m-0 w-100">
        <td >{row.address}</td>
      </tr>
    )
  }

  return (
    <tr ref={reference}>
      <td>{_index}</td>
      <td>{item.name} {item.has_aggrement && <img src={crown} alt='crown' width={20} />}</td>
      <td>{item.email}</td>
      <td>{item.phone}</td>
      <td>{item.document}</td>
      <td>
        <Link to={`/clientes/${item.id}`} className="btn btn-info btn-sm">Editar</Link>
        {/* <button className="btn btn-info btn-sm" onClick={() => onEdit(item)}>Editar</button> */}
      </td>
    </tr>
  )
}
export default RowTableClient;