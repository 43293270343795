import React, { useEffect, useRef, useState } from 'react'
import MenuTemplate from '../../template/menu.template';
import { getInventoryByDetail } from '../../Redux/inventoryDuck'
import { connect } from 'react-redux';
import { Card, Col, Container, Row, Table } from 'react-bootstrap';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from "react-icons/ri";
import { formatNumber } from '../../utils';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';

const ExistenceDetailProducts = ({ getInventoryByDetail, fetching_detail, data_detail }) => {

    const [listLowdown, setListLowdown] = useState([])
    const [listCart, setListCart] = useState([])
    const [listAdditional, setListAdditional] = useState([])
    const [listPurchases, setListPurchases] = useState([])
    const [listInputs, setListInputs] = useState([])
    const [product, setProduct] = useState({})
    const tableRef = useRef(null);
    let { id } = useParams()

    useEffect(() => {
        if (fetching_detail === 2) {
            let { results, product } = data_detail
            let { sales_cart, sales_additional, purchases, lowdowns, inputs } = results

            setProduct(product)
            setListLowdown(lowdowns)
            setListCart(sales_cart)
            setListAdditional(sales_additional)
            setListPurchases(purchases)
            setListInputs(inputs)
        }
    }, [fetching_detail])

    useEffect(() => {
        getInventoryByDetail(id)
    }, [id])



    return (
        <MenuTemplate>
            <Container fluid>
                <Row className='d-flex justify-content-center'>
                    <Col lg={8}>
                        <DownloadTableExcel
                            filename="inventario"
                            sheet="inventario"
                            currentTableRef={tableRef.current}>
                            <RiFileExcel2Fill color='green' size={30} className='cursor-pointer' />
                            {/* <button className='btn btn-sm btn-info'> Export excel </button> */}
                        </DownloadTableExcel>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row className='mt-3 d-flex justify-content-center'>
                            <Col>
                                <Row>
                                    <Col><h4 className='text-center'>VENTAS DE TIENDA - </h4></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Table className='table-sm table-bordered' ref={tableRef}>
                                            <thead className='thead-dark'>
                                                <tr>
                                                    <th></th>
                                                    <th>CART ID</th>
                                                    <th>FECHA</th>
                                                    <th className='text-center'>PRECIO</th>
                                                    <th className='text-center'>CANTIDAD</th>
                                                    <th className='text-center'>TOTAL</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listCart.map((d, k) => <tr key={k}>
                                                    <td>{k + 1}</td>
                                                    <td>{d.id}</td>
                                                    <td>{d.created_at}</td>
                                                    <td align='right'>{formatNumber(d.price)}</td>
                                                    <td align='right'>{d.quantity}</td>
                                                    <td align='right'>{formatNumber(d.total)}</td>
                                                </tr>)}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th colSpan={4}>TOTAL</th>
                                                    <th className='text-right'>{formatNumber(listCart.reduce((a, b) => a + parseFloat(b.quantity || 0), 0))}</th>
                                                    <th className='text-right'>{formatNumber(listCart.reduce((a, b) => a + parseFloat(b.total || 0), 0))}</th>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                        <Row className='d-flex justify-content-center'>
                            <Col>
                                <Row>
                                    <Col><h4 className='text-center'>VENTAS ADICIONALES -</h4></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Table className='table-sm table-bordered' ref={tableRef}>
                                            <thead className='thead-dark'>
                                                <tr>
                                                    <th></th>
                                                    <th>ORDEN ID</th>
                                                    <th>FECHA</th>
                                                    <th className='text-center'>PRECIO</th>
                                                    <th className='text-center'>CANTIDAD</th>
                                                    <th className='text-center'>TOTAL</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listAdditional.map((d, k) => <tr key={k}>
                                                    <td>{k + 1}</td>
                                                    <td>{d.id}</td>
                                                    <td>{d.created_at}</td>
                                                    <td align='right'>{formatNumber(d.price)}</td>
                                                    <td align='right'>{d.quantity}</td>
                                                    <td align='right'>{formatNumber(d.total)}</td>
                                                </tr>)}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th colSpan={4}>TOTAL</th>
                                                    <th className='text-right'>{formatNumber(listAdditional.reduce((a, b) => a + parseFloat(b.quantity || 0), 0))}</th>
                                                    <th className='text-right'>{formatNumber(listAdditional.reduce((a, b) => a + parseFloat(b.total || 0), 0))}</th>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col>

                        <Row>
                            <Col><h4 className='text-center'>COMPRAS +</h4></Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table className='table-sm table-bordered' ref={tableRef}>
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th></th>
                                            <th>COMPRA ID</th>
                                            <th>FECHA</th>
                                            <th className='text-center'>PRECIO</th>
                                            <th className='text-center'>CANTIDAD</th>
                                            <th className='text-center'>TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listPurchases.map((d, k) => <tr key={k}>
                                            <td>{k + 1}</td>
                                            <td>{d.id}</td>
                                            <td>{d.created_at}</td>
                                            <td align='right'>{formatNumber(d.price)}</td>
                                            <td align='right'>{d.quantity}</td>
                                            <td align='right'>{formatNumber(d.total)}</td>
                                        </tr>)}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th colSpan={4}>TOTAL</th>
                                            <th className='text-right'>{formatNumber(listPurchases.reduce((a, b) => a + parseFloat(b.quantity || 0), 0))}</th>
                                            <th className='text-right'>{formatNumber(listPurchases.reduce((a, b) => a + parseFloat(b.total || 0), 0))}</th>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col><h4 className='text-center'>INSUMOS -</h4></Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table className='table-sm table-bordered'>
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th>FECHA</th>
                                            <th>TRABAJDOR</th>
                                            <th>PRODUCTO</th>
                                            <th>CANTIDAD</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listInputs.map((d, k) => <tr key={k}>
                                            <td>{moment(d.created_at).format("YYYY-MM-DD")}</td>
                                            <td>{d.worker}</td>
                                            <td>{d.product}</td>
                                            <td align='right'>{d.quantity}</td>
                                        </tr>)}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th colSpan={3}>TOTAL</th>
                                            <th className='text-right'>{formatNumber(listInputs.reduce((a, b) => a + parseFloat(b.quantity || 0), 0))}</th>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col><h4 className='text-center'>BAJAS -</h4></Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table className='table-sm table-bordered' ref={tableRef}>
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th></th>
                                            <th>BAJA ID</th>
                                            <th>FECHA</th>
                                            <th className='text-center'>PRECIO</th>
                                            <th className='text-center'>CANTIDAD</th>
                                            <th className='text-center'>TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listLowdown.map((d, k) => <tr key={k}>
                                            <td>{k + 1}</td>
                                            <td>{d.id}</td>
                                            <td>{d.registration_date}</td>
                                            <td align='right'>{formatNumber(d.cost)}</td>
                                            <td align='right'>{d.amount}</td>
                                            <td align='right'>{formatNumber(d.total)}</td>
                                        </tr>)}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th colSpan={4}>TOTAL</th>
                                            <th className='text-right'>{formatNumber(listLowdown.reduce((a, b) => a + parseFloat(b.amount || 0), 0))}</th>
                                            <th className='text-right'>{formatNumber(listLowdown.reduce((a, b) => a + parseFloat(b.total || 0), 0))}</th>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Container>
        </MenuTemplate>

    )
}

const mapsState = (props) => {
    let { inventory } = props
    return {
        fetching_detail: inventory.fetching_detail,
        data_detail: inventory.data_detail,
    }
}

export default connect(mapsState, { getInventoryByDetail })(ExistenceDetailProducts);