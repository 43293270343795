import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { getServices, createService, updateService, deleteService } from '../../Redux/serviceDuck'
import { getTaxes } from '../../Redux/parameterDuck'
import { getCategories } from '../../Redux/categoryDuck'
import './index.css'
import TemplateMenu from '../../template/menu.template'
import ItemCardService from './item.card.service';
import ModalService from './modal.service';


const ServicePage = ({ getServices, fetching, data, createService, fetching_edit, updateService, getCategories, categories, category_fetching, deleteService,
    fetching_delete, fetching_list, getTaxes, data_taxes, fetching_taxes }) => {

    const [showModal, setShowModal] = useState(false);
    const [form, setForm] = useState({});
    const [listCategory, setListCategory] = useState([]);
    const [listTaxes, setListTaxes] = useState([]);
    const [list, setList] = useState([]);
    const [filter, setFilter] = useState({ category_id: 0, allow: 0, service_price: [] });

    useEffect(() => {
        getServices(filter)
        getCategories()
        getTaxes()
    }, [])

    useEffect(() => {
        if (fetching_delete === 2) {
            getServices(filter)
        }
        if (fetching_delete === 3) {
            alert("No se puede Eliminar El servicio")
        }

    }, [fetching_delete])

    useEffect(() => {
        if (fetching_taxes === 2) {
            setListTaxes(data_taxes)
        }

    }, [fetching_taxes])

    useEffect(() => {
        if (category_fetching === 2) {
            setListCategory(categories)
        }

    }, [category_fetching])

    useEffect(() => {
        if (fetching_edit === 2) {
            setShowModal(false)
            getServices(filter)
        }

    }, [fetching_edit])

    const onHide = () => {
        setShowModal(false);
    }

    const showModalTmp = () => {
        setShowModal(true);
        setForm({})
    }

    const onSubmit = (form) => {
        createService(form)
    }

    const onUpdate = (form) => {
        updateService(form)
    }

    const onEdit = (row) => {
        setShowModal(true);
        setForm(row);
    }


    const onDelete = (item) => {
        if (window.confirm('¿Realmente deseas Eliminar Este Servicio?')) {
            deleteService(item)
        }
    }

    const onFilter = (e) => {

        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value

        let _filter = filter
        _filter[e.target.name] = value
        setFilter(_filter)
        getServices(_filter)
    }


    return <TemplateMenu>
        <Row className="my-4">
            <Col lg={2} md={4}>
                <h4 className="font-title pt-3">SERVICIOS</h4>
            </Col>
            <Col lg={2} md={4}>
                <button className='btn btn-sm btn-info' onClick={showModalTmp}>Crear</button>
            </Col>
            <Col lg={2} md={4}>
                <div className="form-group">
                    <label>Categoria</label>
                    <select className='form-control' name='category_id' onChange={onFilter}>
                        <option value={0}>TODOS</option>
                        {listCategory.map((d, k) => <option key={k} value={d.id}>{d.description}</option>)}
                    </select>

                </div>
            </Col>
            <Col lg={2}>
                <div className="form-group">
                    <label>Tipo</label>
                    <select className='form-control' name='allow' value={form.is_allow} onChange={onFilter} >
                        <option value={0}>TODOS</option>
                        <option value={1}>Servicios Asociados a Clientes</option>
                        <option value={2}>Para Clientes Generales</option>
                    </select>
                </div>
            </Col>
        </Row>
        <Row>
            <Col lg={12}>
                <Row>
                    {data.map((d, k) => <ItemCardService item={d} key={k} onEdit={onEdit} onDelete={onDelete} />)}
                </Row>
            </Col>
        </Row>
        <ModalService showModal={showModal}
            onHide={onHide}
            onCreate={onSubmit}
            onUpdate={onUpdate}
            formEdit={form}
            categories={listCategory}
            taxes={listTaxes}
            size={data.length}
        />
    </TemplateMenu>
}

const mapsState = (props) => {
    let { service, category, parameter } = props

    return {
        data: service.data,
        fetching: service.fetching_list,
        fetching_edit: service.fetching_edit,
        fetching_delete: service.fetching_delete,
        category_fetching: category.fetching,
        categories: category.data,
        fetching_taxes: parameter.fetching_taxes,
        data_taxes: parameter.data_taxes,
    }
}

export default connect(mapsState, {
    getServices, createService, updateService, getCategories,
    deleteService, getTaxes
})(ServicePage);