import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import TemplateMenu from '../../template/menu.template'
import FormProduct from './form.product';
import { getProductById, createOrUpdateProduct } from '../../Redux/productDuck'

const EditProductPage = ({ getProductById, fetching_by_id, row, createOrUpdateProduct, fetching_edit }) => {
    let { id } = useParams();
    const [form, setForm] = useState([])
    const [title, setTitle] = useState('')

    const history = useHistory();

    useEffect(() => {
        getProductById(id)
    }, [id])


    useEffect(() => {
        if (fetching_edit === 2) {
            history.goBack()
        }
    }, [fetching_edit])
    useEffect(() => {
        if (fetching_by_id === 2) {

            setForm(row)
        }
    }, [fetching_by_id])

    const onSubmit = (form) => {
        if (form.has_inventory === false) {
            if (window.confirm("¿El sistema intentará borrar los datos relacionados a inventario, esta de acuerdo?")) {
                createOrUpdateProduct(form)
            }
        } else {
            createOrUpdateProduct(form)
        }
    }

    return (
        <TemplateMenu>
            <Row>
                <Col><h4>Editar Producto</h4></Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Col lg={8}><FormProduct formEdit={form} onSubmit={onSubmit} /></Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { product } = props

    return {
        fetching_by_id: product.fetching_by_id,
        fetching_edit: product.fetching_edit,
        row: product.row
    }
}

export default connect(mapsState, { getProductById, createOrUpdateProduct })(EditProductPage);