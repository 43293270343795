import React, { useEffect, useState } from 'react'
import { Card, Col, Form, Row, Table } from 'react-bootstrap';
import TemplateMenu from '../../../template/menu.template'
import { createInventoryCount, getInventoryCountById, updateInventoryCountById, confirmInventoryCountById } from '../../../Redux/inventoryDuck'
import { getProductsAdmin } from '../../../Redux/productDuck'
import { connect } from 'react-redux';
import moment from 'moment';
import ItemTableCounter from '../item.table.counter';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ItemEditCount from './item.edit.count';

const EditInventoryCountPage = ({ getInventoryCountById, fetching_by_id, row, createInventoryCount,
    getProductsAdmin, fetching_list_product, data_product, fetching_create, updateInventoryCountById,
    fetching_update, confirmInventoryCountById, fetching_confirm }) => {

    const history = useHistory();
    const [list, setList] = useState({ detail: [] });
    const [form, setForm] = useState({
        product_id: 0,
        registration_date: moment().format("YYYY-MM-DD"),
        daily: false,
        week: false,
        month: false,
    });

    const { id } = useParams()

    useEffect(() => {
        if (fetching_create === 2) {
            history.goBack();
        }
    }, [fetching_create])

    useEffect(() => {
        if (fetching_confirm === 2) {
            history.goBack();
        }
    }, [fetching_confirm])

    useEffect(() => {
        getInventoryCountById(id)
    }, [id])


    useEffect(() => {
        if (fetching_by_id === 2) {
            setList(row)
        }
    }, [fetching_by_id]);

    useEffect(() => {
        if (fetching_update === 2) {
            getInventoryCountById(id)
        }
    }, [fetching_update]);

    const onSave = () => {
        if (window.confirm("¿Realmente deseas confirmar este Conteo?")) {
            confirmInventoryCountById(id)
        }
    };

    const onEdit = (row, quantity) => {
        updateInventoryCountById(row.id, quantity)
    }

    return (
        <TemplateMenu>
            <Row className="my-3">
                <Col lg={3}>
                    <h3>Editar Conteo de Inventario</h3>
                </Col>
                <Col lg={2} className="d-flex align-items-center">
                    <button className="btn btn-sm btn-warning" onClick={onSave}>
                        Confirmar
                    </button>
                </Col>
            </Row>

            <Row className="d-flex justify-content-center">
                <Col lg={11}>
                    <Table className='table table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>PRODUCTO</th>
                                <th>CANTIDAD</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.detail.length > 0 ? list.detail.map((d, k) => <ItemEditCount
                                key={k} item={d}
                                onEdit={onEdit}
                            />) : <tr><td colSpan={3} align='center'>No hay productos</td></tr>}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { inventory } = props;
    return {
        fetching_by_id: inventory.fetching_by_id,
        fetching_update: inventory.fetching_update,
        fetching_confirm: inventory.fetching_confirm,
        row: inventory.row,
    }
}

export default connect(mapsState, {
    createInventoryCount, getProductsAdmin, getInventoryCountById,
    updateInventoryCountById, confirmInventoryCountById
})(EditInventoryCountPage);