import React, { useState, useEffect, Fragment, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { getAllBusiness, onRenoveteBusiness, onAssignBusiness } from '../../Redux/businessDuck'
import { toogleAlert } from '../../Redux/alertDuck'
import { Row, Col, Container } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const SupportPage = ({ getAllBusiness, data, fetching, toogleAlert, current_page, last_page, fetching_search,
    onRenoveteBusiness, user, onAssignBusiness, fetching_change }) => {

    const [list, setList] = useState([])
    const observer = useRef()
    const [status, setStatus] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const [formEdit, setFormEdit] = useState()

    useEffect(() => {
        getAllBusiness()
        toogleAlert(true)
    }, [])


    useEffect(() => {
        if (fetching === 2) {
            toogleAlert(false)

            setList(data)
        }
    }, [fetching])

    useEffect(() => {
        if (fetching_change === 2) {
            getAllBusiness()
            toogleAlert(true)
        }
    }, [fetching_change])

    useEffect(() => {
        if (fetching === 2) {
            toogleAlert(false)

            setList(data)
        }
    }, [fetching])


    const showModalTmp = () => {
        setShowModal(true)
    }

    const filterContent = (txt) => {

    }

    const onCleanFilter = () => {
        getAllBusiness()
    }

    const lastBookElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()

        // console.log('current_page',current_page)

        observer.current = new IntersectionObserver(entries => {

            if (entries[0].isIntersecting && current_page <= last_page) {
                toogleAlert(true, "Cargando datos...")
                getAllBusiness(parseInt(current_page) + 1)

            }
        })

        console.log('node', node)
        if (node) observer.current.observe(node)

    }, [current_page, status])


    const onAssign = (row) => {
        if (window.confirm("Desear reasignar el usuario?")) {
            console.log('row', row);
            onAssignBusiness(row)
        }
    }

    return (
        <TemplateMenu>
            <Row className="mb-3">
                <Col lg={6} className="pl-2">
                    <Link className='btn btn-sm btn-info' to={`/business-create`}>Crear Empresa</Link>
                </Col>
            </Row>

            <Row>
                <Col>
                    <table className="table table-sm table-hover table-bordered">
                        <thead className='thead-dark'>
                            <tr>
                                <th>Id</th>
                                <th>Alias</th>
                                <th>Nombre Cliente</th>
                                <th>Actual</th>
                                <th>Asignar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(list !== undefined) ? list.map((row, i) => <tr key={i}>
                                <td>{row.id}</td>
                                <td>{row.business}</td>
                                <td>{row.business_name}</td>
                                <td>{row.id === user.business_id ? 'Asignado' : ''}</td>
                                <td>
                                    {row.id !== user.business_id &&
                                        <button className='btn btn-sm btn-info' onClick={() => onAssign(row)}>Asignar</button>}

                                </td>
                            </tr>) : null}
                        </tbody>
                    </table>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { business, user } = props
    let { data, fetching_list, current_page, last_page, fetching_search } = business

    return {
        data: data,
        fetching: fetching_list,
        current_page: current_page,
        last_page,
        fetching_search,
        user: user.data,
        fetching_change: business.fetching_change
    }
}

export default connect(mapsState, { getAllBusiness, toogleAlert, onRenoveteBusiness, onAssignBusiness })(SupportPage);