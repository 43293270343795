import moment from 'moment';
import React from 'react'
import { formatNumber } from '../../utils';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Col, Row } from 'react-bootstrap';

const ItemTableBriefcase = ({ item, onSelectItem }) => {
    return (
        <tr className={`${item.checked === true ? 'table-info' : ''}`}>
            <td>{moment(item.finish_at).format("YYYY-MM-DD")}</td>
            <td>{item.id}</td>
            <td>{item.client}</td>
            <td>{item.phone}</td>
            <td>{item.category}</td>
            <td>{item.plate}</td>
            <td align='right'>{formatNumber(item.total)}</td>
            <td align='center'>
                <input type='checkbox' checked={item.checked} onChange={(e) => onSelectItem(item.id)} />
            </td>
        </tr>
    )
}

export default ItemTableBriefcase;