import React, { Fragment, useState } from 'react'
import { Col, ListGroup, Row } from 'react-bootstrap';
import { MdCheck, MdClose } from "react-icons/md";

const ItemClient = ({ row, onNotifier }) => {
    const [checked, setChecked] = useState(false)

    const onChange = () => {
        setChecked(!checked)
        row.checked = !checked;
    }

    return (
        <ListGroup.Item className={`p-1 ${row.phone.length === 12 ? "list-group-item-success" : 'list-group-item-danger'}`}>
            <Row>
                <Col lg={2}>
                    {row.phone.length === 12 && <input className='input-sm form-control' type={"checkbox"} checked={checked} onChange={onChange} />}
                </Col>
                <Col>
                    <Row>
                        <Col>Nombre</Col>
                        <Col>{row.name}</Col>
                    </Row>
                    <Row>
                        <Col>Celular</Col>
                        <Col><b>{row.phone} {row.phone.length === 12 ? <MdCheck size={25} /> : <MdClose size={25} />}</b></Col>
                    </Row>
                    <Row>
                        <Col>
                            {row.recurrent.length > 0 ? row.recurrent.map((value, k) => (
                                <Fragment key={k}>
                                    <Row>
                                        <Col>Categoría</Col>
                                        <Col>{value.category.title}</Col>
                                    </Row>
                                    <Row>
                                        <Col>PLACA</Col>
                                        <Col>{value.plate}</Col>
                                    </Row>
                                </Fragment>
                            )) : null}
                        </Col>
                    </Row>

                </Col>

            </Row>


        </ListGroup.Item>
    )
}

export default ItemClient;