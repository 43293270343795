import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getListOrderById } from '../../Redux/orderDuck'
import moment from 'moment';
import { Container } from 'react-bootstrap';

const OrderDetailPdf = ({ getListOrderById, fetching_by_id, row, fetching_me, data_me }) => {
    let { id } = useParams()
    const [data, setData] = useState({ client: { name: '' } })
    const [business, setBusiness] = useState({ business_name: '' })
    const [detail, setDetail] = useState([])
    const [photos, setPhotos] = useState([])

    useEffect(() => {
        if (fetching_by_id === 2) {
            setData(row)
            setDetail(row.detail)
            setPhotos(row.photos)
        }
    }, [fetching_by_id])
    useEffect(() => {
        if (fetching_me === 2) {
            setBusiness(data_me.business)
        }
    }, [fetching_me])

    useEffect(() => {
        getListOrderById(id)
    }, [id])

    return (
        <Container>
            <p>asdasd</p>
        </Container>
    )
}

const mapsState = (props) => {
    let { order, user } = props

    return {
        fetching_by_id: order.fetching_by_id,
        row: order.row,
        fetching_me: user.fetching_me,
        data_me: user.user,
    }
}

export default connect(mapsState, { getListOrderById })(OrderDetailPdf);