import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'

import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { getDetailOrderWorker } from '../../Redux/orderDuck';
import { Card, Col, Row, Table } from 'react-bootstrap';
import moment from 'moment';
import SubItemTableOrder from './subitem.table.order';
import { toogleLoader } from '../../Redux/alertDuck';
import { MdAddCircle, MdClose, MdOutlineTurnSharpLeft } from 'react-icons/md';
import { formatNumber } from '../../utils';


const OrderWorkerPayment = ({ getDetailOrderWorker, fetching_worker_detail, data_detail, toogleLoader }) => {
    const search = useLocation().search;
    const cashier_id = new URLSearchParams(search).get("cashier_id");
    const date_init = new URLSearchParams(search).get("date_init");
    const date_end = new URLSearchParams(search).get("date_end");
    const status_id = new URLSearchParams(search).get("status_id");
    const operator_id = new URLSearchParams(search).get("operator_id");
    const [list, setList] = useState([])

    useEffect(() => {
        if (fetching_worker_detail === 2) {
            setList(data_detail.results)
            toogleLoader(false)

        }
    }, [fetching_worker_detail])

    useEffect(() => {
        toogleLoader(true)
        getDetailOrderWorker({ cashier_id, date_init, date_end, status_id, operator_id })
    }, [cashier_id, date_init, date_end])


    const onEditPercent = () => {
        console.log('edit percent')
    }

    const onOpenModalExpense = (item) => {

    }

    const onDeleteRow = () => {

    }

    return (
        <TemplateMenu>
            <Row>
                <Col>Pago a Jorge</Col>
            </Row>
            <Row>
                <Col>
                    {list.map((a, k) => <Row key={k} className='mt-3'>
                        <Col lg={6}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Turno ({a.id}) ({a.name})</Card.Title>
                                    <Row className='my-1'>
                                        <Col><b>Fecha Apertura</b></Col>
                                        <Col>{moment(a.date_start).format("YYYY-MM-DD")}</Col>
                                        <Col><b>Fecha Cierre</b></Col>
                                        <Col>{moment(a.date_end).format("YYYY-MM-DD")}</Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Table className='table table-sm table-bordered'>

                                                <thead className='thead-dark'>
                                                    <tr>
                                                        <th>Fecha</th>
                                                        <th>Orden</th>
                                                        <th>Vehículo</th>
                                                        <th>Servicio</th>
                                                        <th>Tipo</th>
                                                        <th>Valor</th>
                                                        <th>Prop</th>
                                                        <th>Precio</th>
                                                        <th>SubTotal</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {a.orders.length > 0 ? a.orders.map((d, k) => <SubItemTableOrder key={k} item={d} onEditPercent={onEditPercent} />) : <tr>
                                                        <td colSpan={9} align='center'>No hay Datos</td></tr>}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th colSpan={7}>SubTotal</th>
                                                        <td align='right'>
                                                            {/* <b>{formatNumber(item.subtotal_real)}</b> */}
                                                        </td>
                                                        {/* <td align='right'><b>{formatNumber(item.payment_worker_real)}</b></td> */}
                                                    </tr>
                                                    <tr>
                                                        <th colSpan={8}>Total Descuentos</th>
                                                        {/* <td align='right'><b>{formatNumber(item.discount)}</b></td> */}
                                                    </tr>
                                                    <tr>
                                                        <th colSpan={8}>Total Propinas</th>
                                                        {/* <td align='right'><b>{formatNumber(item.tip)}</b></td> */}
                                                    </tr>
                                                    <tr>
                                                        <th colSpan={8}>TOTAL A PAGAR</th>
                                                        {/* <td align='right'><b>{formatNumber(item.total)}</b></td> */}
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Row>
                                <Col className='text-center'><h5>Anticipos<MdAddCircle className='cursor-pointer' onClick={() => onOpenModalExpense(a, 1)} /></h5> </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <Table className='table table-sm table-bordered'>
                                                <thead className='thead-dark'>
                                                    <tr>
                                                        <th>Fecha</th>
                                                        <th>Descripción</th>
                                                        <th>Valor</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {a.discount_worker.filter(d => d.type_move_id === 1).length > 0 ? a.discount_worker.filter(d => d.type_move_id === 1).map((d, k) => <tr key={k}>
                                                        <td>{moment(d.registration_date).format("YYYY-MM-DD")}</td>
                                                        <td >{d.description}</td>
                                                        <td align='right'>{formatNumber(d.price)}</td>
                                                        <td align='right'><MdClose className='cursor-pointer' onClick={() => onDeleteRow(d)} /></td>
                                                    </tr>) : <tr><td colSpan={2}>No hay datos</td></tr>}
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                        </Col>
                        <Col>
                            <Row>
                                <Col className='text-center'><h5>Insumos<MdAddCircle className='cursor-pointer' onClick={() => onOpenModalExpense(a, 1)} /></h5> </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <Table className='table table-sm table-bordered'>
                                                <thead className='thead-dark'>
                                                    <tr>
                                                        <th>Fecha</th>
                                                        <th>Descripción</th>
                                                        <th>Valor</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {a.discount_worker.filter(d => d.type_move_id === 1).length > 0 ? a.discount_worker.filter(d => d.type_move_id === 1).map((d, k) => <tr key={k}>
                                                        <td>{moment(d.registration_date).format("YYYY-MM-DD")}</td>
                                                        <td >{d.description}</td>
                                                        <td align='right'>{formatNumber(d.price)}</td>
                                                        <td align='right'><MdClose className='cursor-pointer' onClick={() => onDeleteRow(d)} /></td>
                                                    </tr>) : <tr><td colSpan={2}>No hay datos</td></tr>}

                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>


                        </Col>

                    </Row>)}
                </Col>
            </Row>

        </TemplateMenu>

    )
}

const mapsState = (props) => {
    let { order } = props;
    console.log('order', order);

    return {
        fetching_worker_detail: order.fetching_worker_detail,
        data_detail: order.data_detail,
    }
}

export default connect(mapsState, { getDetailOrderWorker, toogleLoader })(OrderWorkerPayment);