import moment from 'moment';
import React, { useState } from 'react'
import { Card, Col, Container, Modal, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatNumber } from '../../utils';
import { RiMoneyDollarCircleFill } from "react-icons/ri";


const ModalPayment = ({ show, data, onPayment, onHide, listPayment, turn_active, listTurn }) => {

    let { expenses, orders } = data || { orders: { subtotal_real: 0 } }
    const { detail } = orders || { detail: [], subtotal_real: 0 }


    const [form, setForm] = useState({ method_payment_id: 0, turn_id: 0 })

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onPaymentPre = (data) => {
        if (form.method_payment_id === 0) {
            alert("Escoge un Método de pago")
            return;
        }
        data.method_payment_id = form.method_payment_id
        data.turn_id = form.turn_id
        onPayment(data)
    }


    return (
        <Modal show={show} size={"lg"} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{data.name}, {orders.days_worked} días Trabajados</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Distribución de Pagos</label>
                                <select className='form-control' name='turn_id' value={form.turn_id} onChange={(e) => setForm({ ...form, ["turn_id"]: e.target.value })}>
                                    <option value={0}>ASIGNAR A CADA TURNO</option>
                                    {listTurn.map((d, k) => <option key={k} value={d.id}>({d.id}) {d.user.name} ({d.date_start}) - ({d.date_end})</option>)}
                                </select>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='text-center'><h5>Servicios Realizados</h5></Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table className='table-sm table-bordered'>

                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Categoria</th>
                                        <th>Producto</th>
                                        <th>Precio</th>
                                        <th>Porcentaje</th>
                                        <th>SubTotal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {detail.length > 0 && detail.map((d, k) => <tr key={k}>
                                        <td>{moment(d.finish_at).format("YYYY-MM-DD")}</td>
                                        <td>{d.category}</td>
                                        <td>{d.service}</td>
                                        <td align='right'>{formatNumber(d.price_real)}</td>
                                        <td align='right'>{d.percent}%</td>
                                        <td align='right'>{formatNumber(d.price_to_paid_real)}</td>
                                    </tr>)}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={3}>Total</th>
                                        <td align='right'><b>{formatNumber(orders.subtotal_real)}</b></td>
                                        <th></th>
                                        <td align='right'><b>{formatNumber(orders.payment_worker_real)}</b></td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='text-center'><h5>Anticipos</h5></Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table className='table-sm table-bordered'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Descripción</th>
                                        <th>Valor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {expenses.filter(d => d.type_move_id === 1).length > 0 && expenses.filter(d => d.type_move_id === 1).map((d, k) => <tr key={k}>
                                        <td>{moment(d.created_at).format("YYYY-MM-DD")}</td>
                                        <td >{d.description}</td>
                                        <td align='right'>{formatNumber(d.price)}</td>
                                    </tr>)}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={2}>Total</th>
                                        <td align='right'><b>{formatNumber(expenses.filter(d => d.type_move_id === 1).reduce((a, b) => a + parseFloat(b.price), 0))}</b></td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='text-center'><h5>Insumos</h5></Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table className='table-sm table-bordered'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Descripción</th>
                                        <th>Valor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {expenses.filter(d => d.type_move_id === 2).length > 0 && expenses.filter(d => d.type_move_id === 2).map((d, k) => <tr key={k}>
                                        <td>{moment(d.created_at).format("YYYY-MM-DD")}</td>
                                        <td >{d.description}</td>
                                        <td align='right'>{formatNumber(d.price)}</td>
                                    </tr>)}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={2}>Total</th>
                                        <td align='right'><b>{formatNumber(expenses.filter(d => d.type_move_id === 2).reduce((a, b) => a + parseFloat(b.price), 0))}</b></td>

                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Método de pago</label>
                                <select className='form-control' name='method_payment_id' onChange={updateInput}>
                                    <option value={0}>Seleccione</option>
                                    {listPayment.map((d, k) => <option key={k} value={d.id}>{d.description}</option>)}
                                </select>

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col><b>TOTAL A PAGAR</b></Col>
                        <Col className='text-right'><b>{formatNumber(orders.total)}</b></Col>
                    </Row>
                </Container>

            </Modal.Body>
            <Modal.Footer>
                <Row >
                    <Col>
                        {turn_active === null ? <Link to={`/turnos`} className="btn btn-sm btn-danger">No Tienes turno Activo, Abrir Turno</Link>
                            : <button className='btn btn-sm btn-success' onClick={() => onPaymentPre(data)}>Pagar</button>}
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalPayment;