import { formatNumber } from "../../utils";

const ItemTableFE = ({ item, onSelectItem }) => {
    return (
        <tr>
            <td>{item.id}</td>
            <td>{item.id}</td>
            <td className='text-right'>{formatNumber(item.subtotal)}</td>
            <td className='text-right'>{formatNumber(item.total)}</td>
            <td className='text-right'>
                <input type='checkbox' className="form-control" checked={item.checked} onChange={(e) => onSelectItem(item.id)} />
            </td>
        </tr>
    )
}

export default ItemTableFE;