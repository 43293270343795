import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { getOrderByClient } from '../../Redux/reportDuck'
import { connect } from 'react-redux';
import moment from 'moment';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from 'react-icons/ri';
import ItemDetailByClient from './item.detail.by_client';
import { toogleLoader } from '../../Redux/alertDuck';
import { formatNumber } from '../../utils';

const ReportByClientForm = ({ fetching_client, data, getOrderByClient, fetching_payment, data_payment, toogleLoader }) => {

    const [form, setForm] = useState({ date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD"), client_id: 0, is_detail: false })
    const [listClient, setListClient] = useState([])
    const [listReport, setListReport] = useState([])
    const [listReportFilter, setListReportFilter] = useState([])

    const tableRef = useRef(null);


    useEffect(() => {
        if (fetching_client === 2) {

            setListClient(data)
        }
    }, [fetching_client])

    useEffect(() => {
        if (fetching_payment === 2) {
            setListReport(data_payment)
            setListReportFilter(data_payment)
            toogleLoader(false)
        }
    }, [fetching_payment])

    const updateField = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const onSearch = () => {
        toogleLoader(true)
        getOrderByClient(form)
    }

    const onSearchText = (e) => {

        const filtered = listReport.filter((item) =>
            item.client.toLowerCase().includes(e.target.value.toLowerCase())
        );

        setListReportFilter(filtered)
    }

    return (<Container fluid>
        <Row className='my-3'>
            <Col lg={2}>
                <div className="form-group">
                    <label>Cliente</label>
                    <select className='form-control' name='client_id' value={form.client_id} onChange={updateField}>
                        <option value={0}>Todos</option>
                        {listClient.map((d, k) => <option key={k} value={d.id}>{d.name}</option>)}
                    </select>
                </div>
            </Col>
            <Col lg={2}>
                <div className="form-group">
                    <label>Fecha de Inicio</label>
                    <input
                        type="date"
                        className="form-control"
                        value={form.date_init}
                        name="date_init"
                        onChange={updateField} />
                </div>
            </Col>
            <Col lg={2}>
                <div className="form-group">
                    <label>Fecha Fin</label>
                    <input
                        type="date"
                        className="form-control"
                        value={form.date_end}
                        name="date_end"
                        onChange={updateField}
                    />
                </div>
            </Col>
            <Col lg={2}>
                <div className="form-group">
                    <label>Ver Con Detalle</label>
                    <input
                        type="checkbox"
                        className="form-control"
                        value={form.is_detail}
                        name="is_detail"
                        onChange={(e) => setForm({
                            ...form,
                            is_detail: e.target.checked
                        })}
                    />
                </div>
            </Col>
            <Col lg={2} className="d-flex align-items-center">
                <button className='btn btn-sm btn-info btn-success' onClick={onSearch}>Buscar</button>
            </Col>
        </Row>
        <Row className='my-1'>
            <Col lg={1} className='d-flex align-items-center'>
                <DownloadTableExcel
                    filename="orders_by_clients"
                    sheet="orders"
                    currentTableRef={tableRef.current}>
                    <RiFileExcel2Fill color='green' className='cursor-pointer' />
                </DownloadTableExcel>
            </Col>
            <Col lg={3}>
                {listReport.length > 0 &&
                    <input className='form-control' placeholder='Buscar Cliente' onChange={onSearchText} />}

            </Col>
        </Row>
        <Row>
            <Col>
                <Table className='table table-sm table-bordered' ref={tableRef}>
                    <thead className='thead-dark'>
                        <tr>
                            <th></th>
                            <th># Orden</th>
                            <th>Fecha</th>
                            <th>Cliente</th>
                            <th>Telefono</th>
                            <th>Categoria</th>
                            <th>Placa</th>
                            {form.is_detail ? <>
                                <th>Servicio</th>
                                <th>Costo</th>
                            </>
                                : <>
                                    <th className='text-center'>TOTAL</th>
                                </>}

                        </tr>
                    </thead>
                    <tbody>
                        {(listReportFilter.length === 0) ? <tr><td colSpan={7} align="center">No hay registros</td></tr> :
                            listReportFilter.map((d, k) => <ItemDetailByClient key={k} item={d} index={k} is_detail={form.is_detail} />)}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan={7}>TOTAL</th>
                            <th className='text-right'>{formatNumber(listReportFilter.reduce((a, b) => a + parseFloat(b.total), 0))}</th>
                        </tr>
                    </tfoot>
                </Table>
            </Col>
        </Row>
    </Container>
    )
}
const mapsState = (props) => {

    let { client, report } = props

    return {
        fetching_client: client.fetching_client,
        data: client.clients,
        fetching_payment: report.fetching_payment,
        data_payment: report.data_payment,
    }
}

export default connect(mapsState, { getOrderByClient, toogleLoader })(ReportByClientForm);