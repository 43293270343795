import React, { useEffect, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import TemplateMenu from '../../template/menu.template'
import { getListInventory } from '../../Redux/inventoryDuck'
import ItemTableInventory from './item.table.inventory';
import { getProductInventory } from '../../Redux/productInventoryDuck';

const InventoryPage = ({ getListInventory, fetching_list, data, getProductInventory, fetching_list_product, products }) => {

    const [list, setList] = useState([])
    const [listFilter, setListFilter] = useState([])
    const [form, setForm] = useState({ product_id: '' })
    const [listProduct, setListProduct] = useState([])

    useEffect(() => {
        getListInventory()
        getProductInventory();
    }, [])

    useEffect(() => {
        if (fetching_list_product === 2) {
            setListProduct(products)
        }
    }, [fetching_list_product])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
            setListFilter(data)
        }
    }, [fetching_list])

    const updateInput = (e) => {
        let _form = {
            ...form,
            [e.target.name]: e.target.value
        }
        setForm(_form)


        getListInventory(_form)
    }

    return (
        <TemplateMenu>
            <Row className='my-3'>
                <Col><h2>Inventario</h2></Col>
            </Row>
            <Row className='my-2'>
                <Col lg={2}>
                    <select className='form-control' name='product_id' value={form.product_id} onChange={updateInput}>
                        <option value={0}>Todos</option>
                        {listProduct.map((p, k) => <option key={k} value={p.id}>{p.name}</option>)}
                    </select>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table className='table-bordered table-sm'>
                        <thead className='thead-dark'>
                            <tr>
                                <th rowSpan={2} align="center">Fecha</th>
                                <th rowSpan={2}>Producto</th>
                                <th rowSpan={2}>Medida</th>
                                <th rowSpan={2} className="text-center">Descripción</th>
                                <th rowSpan={2} className="text-center">Valor Unit</th>
                                <th colSpan={2} className="text-center">Entradas</th>
                                <th colSpan={2} className="text-center">Salidas</th>
                                <th colSpan={2} className="text-center">Saldo</th>
                            </tr>
                            <tr>

                                <th className="text-center">Cantidad</th>
                                <th className="text-center">Valor</th>
                                <th className="text-center">Cantidad</th>
                                <th className="text-center">Valor</th>
                                <th className="text-center">Cantidad</th>
                                <th className="text-center">Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listFilter.length > 0 ? listFilter.map(row => <ItemTableInventory row={row} key={row.id} />) : <tr><td colSpan={12} align="center">No se encontraron registros</td></tr>}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </TemplateMenu>
    )
}
const mapsState = (props) => {
    let { inventory, product_inventory } = props
    return {
        fetching_list: inventory.fetching_list,
        data: inventory.data,
        products: product_inventory.data,
        fetching_list_product: product_inventory.fetching_list,
    }
}

export default connect(mapsState, { getListInventory, getProductInventory })(InventoryPage);