import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { connect } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { getBusinessById } from '../../Redux/businessDuck'

const EFBusinessPage = ({ getBusinessById, fetching_by_id, row }) => {

    const [form, setForm] = useState({})

    let { id } = useParams()

    useEffect(() => {
        getBusinessById(id)
    }, [id])

    useEffect(() => {
        if (fetching_by_id === 2) {
            setForm(row)
        }
    }, [fetching_by_id])

    return (
        <TemplateMenu>
            <Row>
                <Col>Datos Facturación Electronica</Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { business } = props
    return {
        fetching_by_id: business.fetching_by_id,
        row: business.row,
        errors: {}
    }
}
export default connect(mapsState, { getBusinessById })(EFBusinessPage);