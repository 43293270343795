import React, { useEffect } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import { formatNumber } from '../../../utils';

const ReviewProductTotal = ({ total_cart, total_additional, total_inputs, quantity_cart,
    quantity_additional, quantity_input }) => {
    return (
        <Container fluid>
            <Row>
                <Col></Col>
                <Col></Col>
                <Col></Col>
            </Row>
            <Row className='mt-2'>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>TOTAL TIENDA</Card.Title>
                            <Row>
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>CANTIDAD</Card.Title>
                                            <Row>
                                                <Col>{quantity_cart}</Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>TOTAL</Card.Title>
                                            <Row>
                                                <Col className='text-right'>{formatNumber(total_cart)}</Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>TOTAL ADICIONALES</Card.Title>
                            <Row>
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>CANTIDAD</Card.Title>
                                            <Row>
                                                <Col>{quantity_additional}</Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>TOTAL</Card.Title>
                                            <Row>
                                                <Col className='text-right'>{formatNumber(total_additional)}</Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>TOTAL INSUMOS</Card.Title>
                            <Row>
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>CANTIDAD</Card.Title>
                                            <Row>
                                                <Col>{quantity_input}</Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>TOTAL</Card.Title>
                                            <Row>
                                                <Col className='text-right'>{formatNumber(total_inputs)}</Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default ReviewProductTotal;