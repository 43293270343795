
import { getHeaders, validateInput } from '../utils'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_create: 0,
    fetching_delete: 0,
    data: [],
    total: 0,
    errors: {}
}


const LIST_EXPENSE = "LIST_EXPENSE"
const LIST_EXPENSE_SUCCESS = "LIST_EXPENSE_SUCCESS"
const DEFAULT_ERROR = "DEFAULT_ERROR"
const CREATE_EXPENSE = "CREATE_EXPENSE"
const CREATE_EXPENSE_SUCCESS = "CREATE_EXPENSE_SUCCESS"
const CREATE_EXPENSE_ERROR = "CREATE_EXPENSE_ERROR"
const DELETE_EXPENSE = "DELETE_EXPENSE"
const DELETE_EXPENSE_SUCCESS = "DELETE_EXPENSE_SUCCESS"
const EDIT_EXPENSE = "EDIT_EXPENSE"
const EDIT_EXPENSE_SUCCESS = "EDIT_EXPENSE_SUCCESS"
const EXPENSE_RURRENT = "EXPENSE_RURRENT"
const EXPENSE_RURRENT_SUCCESS = "EXPENSE_RURRENT_SUCCESS"
const SAVE_EXPENSE_RURRENT = "SAVE_EXPENSE_RURRENT"
const SAVE_EXPENSE_RURRENT_SUCCESS = "SAVE_EXPENSE_RURRENT_SUCCESS"


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_EXPENSE:
            return { ...state, fetching_list: 1 }
        case LIST_EXPENSE_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload, total: action.total }
        case CREATE_EXPENSE:
            return { ...state, fetching_create: 1 }
        case CREATE_EXPENSE_SUCCESS:
            return { ...state, fetching_create: 2, errors: {} }
        case CREATE_EXPENSE_ERROR:
            return { ...state, fetching_create: 3, errors: action.payload }
        case DELETE_EXPENSE:
            return { ...state, fetching_delete: 1 }
        case DELETE_EXPENSE_SUCCESS:
            return { ...state, fetching_delete: 2 }
        case EDIT_EXPENSE:
            return { ...state, fetching_create: 1 }
        case EDIT_EXPENSE_SUCCESS:
            return { ...state, fetching_create: 2 }
        case EXPENSE_RURRENT:
            return { ...state, fetching_list: 1 }
        case EXPENSE_RURRENT_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case SAVE_EXPENSE_RURRENT:
            return { ...state, fetching_create: 1 }
        case SAVE_EXPENSE_RURRENT_SUCCESS:
            return { ...state, fetching_create: 2 }
        default:
            return state;
    }
}

export let getListCost = (form) => (dispatch, getState) => {
    dispatch({
        type: LIST_EXPENSE
    })


    let { date_init, date_end, method_payment_id, concept_id, deleted, turn_id } = form || JSON.parse(localStorage.getItem("date"));


    if (getState().user.loggedIn) {

        method_payment_id = method_payment_id || 0
        concept_id = concept_id || 0
        turn_id = turn_id || 0
        deleted = deleted || false

        let branch = JSON.parse(localStorage.getItem("branch"))

        fetch(`${API_URL}/api/cost/list-operation?date_init=${date_init}&date_end=${date_end}&method=${method_payment_id}&concept_id=${concept_id}&branch_id=${branch.id}&deleted=${deleted}&turn_id=${turn_id}`, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: LIST_EXPENSE_SUCCESS,
                payload: resp.results,
                total: resp.total,
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let onDeleteExpense = (id) => (dispatch, getState) => {
    dispatch({
        type: DELETE_EXPENSE
    })



    if (getState().user.loggedIn) {

        fetch(`${API_URL}/api/cost/operation/${id}`, {
            method: "DELETE",
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: DELETE_EXPENSE_SUCCESS,
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}
export let onDeleteExpenseWorker = (id) => (dispatch, getState) => {
    dispatch({
        type: DELETE_EXPENSE
    })

    if (getState().user.loggedIn) {

        fetch(`${API_URL}/api/cost/worker/${id}`, {
            method: "DELETE",
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: DELETE_EXPENSE_SUCCESS,
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let onEditExpense = (form) => (dispatch, getState) => {
    dispatch({
        type: EDIT_EXPENSE
    })



    if (getState().user.loggedIn) {

        fetch(`${API_URL}/api/cost/operation/${form.id}`, {
            method: "PUT",
            body: JSON.stringify(form),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: EDIT_EXPENSE_SUCCESS,
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let onCreateExpense = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_EXPENSE
    })

    const rules = [
        { field: "registration_date", msg: "La fecha es requerida" },
        { field: "parameter_id", msg: "El Concepto es requerido" },
        { field: "description", msg: "La descripción es requerida" },
        { field: "value", msg: "El valor es requerido" },
        { field: "method_payment_id", msg: "El Concepto es requerido" },
        { field: "cashier_id", msg: "El Turno es requerido" },
    ]

    console.log('form', form);


    let val = validateInput(form, rules)

    if (val !== null) {
        dispatch({
            type: CREATE_EXPENSE_ERROR,
            payload: val
        })

        return;
    }

    if (getState().user.loggedIn) {

        fetch(`${API_URL}/api/cost/operation`, {
            method: "POST",
            body: JSON.stringify(form),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: CREATE_EXPENSE_SUCCESS,
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}



export let getRecurrentCost = (form) => (dispatch, getState) => {
    dispatch({
        type: EXPENSE_RURRENT
    });


    if (getState().user.loggedIn) {

        fetch(`${API_URL}/api/cost/recurrent?date_init=${form.date_init}&date_end=${form.date_end}`, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: EXPENSE_RURRENT_SUCCESS,
                payload: resp.results,
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let saveRecurrentCost = (form) => (dispatch, getState) => {
    dispatch({
        type: SAVE_EXPENSE_RURRENT
    });


    if (getState().user.loggedIn) {

        fetch(`${API_URL}/api/cost/recurrent`, {
            method: "POST",
            body: JSON.stringify(form),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: SAVE_EXPENSE_RURRENT_SUCCESS,
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}