import React, { useState } from 'react'
import { Card, Col, Modal, Row } from 'react-bootstrap';
import { formatNumber } from '../../utils';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';

const ModalPaymentPayroll = ({ total, show, onHide, turn_active, listMethod, onSubmit }) => {

    const [form, setForm] = useState({ method_payment_id: 0 });

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }
    const onPaymentPre = () => {
        if (form.method_payment_id !== 0) {
            onSubmit(form.method_payment_id)
        }
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Body>
                <Modal.Title>Pagar</Modal.Title>
                <Row>
                    {(turn_active !== undefined && turn_active !== null) && <Col className='text-center'>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col><RiMoneyDollarCircleFill size={30} /></Col>
                                </Row>
                                <Row>
                                    <Col>Turno</Col>
                                    <Col>({turn_active.id}) - {turn_active.user.name}</Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col>Fecha de Apertura</Col>
                                    <Col>{turn_active.date_start}</Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>}

                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Método de pago</label>
                            <select className='form-control' name='method_payment_id' onChange={updateInput}>
                                <option value={0}>Seleccione</option>
                                {listMethod.filter(d => d.id === 51
                                    || d.id === 6
                                    || d.id === 4
                                    || d.id === 5).
                                    map((d, k) => <option key={k} value={d.id}>{d.description}</option>)}
                            </select>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col><b>TOTAL A PAGAR</b></Col>
                    <Col className='text-right'><b>{formatNumber(total)}</b></Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row >
                    <Col>
                        {turn_active === null ? <Link to={`/turnos`} className="btn btn-sm btn-danger">No Tienes turno Activo, Abrir Turno</Link>
                            : <button className='btn btn-sm btn-success' onClick={onPaymentPre}>Pagar</button>}
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalPaymentPayroll;