import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { Card, Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { onCreateInvoice, getDataCreate, getListOrders } from '../../Redux/fetDuck'
import { getAllClients } from '../../Redux/clientDuck'
import InputField from '../Components/Widgets/InputField';
import moment from 'moment';
import { formatNumber } from '../../utils';
import ItemTableFE from './item.table.fe';
import { toogleAlert } from '../../Redux/alertDuck'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const FECreatePage = ({ onCreateInvoice, getDataCreate, data_create, fetching_data_create, getAllClients, fetching_client,
    data_clients, getListOrders, fetching_order, data_order, toogleAlert, fetching_create_invoice }) => {

    const [form, setForm] = useState({
        'invoice_number': '',
        "date": moment().format("YYYY-MM-DD"),
        "hour": moment().format("hh:mm:ss"),
        "payment_form_id": 2,
        "payment_method_id": 30,
        "customer_id": 3003,
        "notes": '',
    })
    const [listPaymentForm, setListPaymentForm] = useState([])
    const [listPaymentMethod, setListPaymentMethod] = useState([])
    const [listClients, setListClients] = useState([])
    const [filter, setFilter] = useState({ client_id: 0, date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD") })
    const [list, setList] = useState([])
    const [listFiltered, setListFiltered] = useState([])
    const history = useHistory()

    useEffect(() => {
        if (fetching_data_create === 2) {
            let { payment_forms, payment_methods, invoice } = data_create
            setListPaymentForm(payment_forms)
            setListPaymentMethod(payment_methods)
            setForm({
                ...form,
                "invoice_number": invoice
            })
        }
    }, [fetching_data_create])

    useEffect(() => {
        if (fetching_create_invoice === 2) {
            alert("Creado correctamente")
            getListOrders(filter)
        }
    }, [fetching_create_invoice])

    useEffect(() => {
        if (fetching_client === 2) {
            setListClients(data_clients)
        }
    }, [fetching_client])

    useEffect(() => {
        if (fetching_order === 2) {
            let list = data_order.map(item => ({ ...item, checked: false }))
            setList(list)
            setListFiltered(list)
            toogleAlert(false)
        }
    }, [fetching_order])

    useEffect(() => {
        getAllClients()
        getDataCreate()
    }, [])

    const onInvoice = () => {
        toogleAlert(true)
        let _form = form;

        _form.orders = listFiltered.filter(d => d.checked === true).map(item => item.id);
        onCreateInvoice(form)
    }
    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const updateInputFilter = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        })
    }

    const onSearch = () => {
        toogleAlert(true)
        getListOrders(filter)
    }

    const handleCheck = (id) => {
        setListFiltered(listFiltered.map(item =>
            item.id === id ? { ...item, checked: !item.checked } : item
        ));
    }

    return (
        <TemplateMenu>
            <Row>
                <Col lg={7}>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Cliente</label>
                                <select className='form-control' name="client_id" value={filter.client_id} onChange={updateInputFilter}>
                                    <option value={0}>Seleccione</option>
                                    {listClients.map((a, b) => <option key={b} value={a.id}>{a.name}</option>)}
                                </select>
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Fecha de inicio</label>
                                <input className='form-control' placeholder='Fecha de Inicio' name='date_init'
                                    value={filter.date_init}
                                    onChange={updateInputFilter} />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Fecha de Fin</label>
                                <input className='form-control' placeholder='Fecha de Fin' name='date_end'
                                    value={filter.date_end}
                                    onChange={updateInputFilter} />
                            </div>
                        </Col>
                        <Col lg={1} className='d-flex align-items-center'>
                            <button className='btn btn-sm btn-info' onClick={onSearch}>Buscar</button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table className='table table-sm table-bordered'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Orden</th>
                                        <th>Cliente</th>
                                        <th>Subtotal</th>
                                        <th>Total</th>
                                        <th>Marcar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listFiltered.length === 0 ? <tr><td colSpan={4} className='text-center'>Sin Datos</td></tr> :
                                        listFiltered.map((a, b) => <ItemTableFE item={a} key={b} onSelectItem={handleCheck} />)}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header>
                                    <Row>
                                        <Col><h5>Factura: {form.invoice_number}</h5></Col>
                                        <Col className='d-flex justify-content-end'>
                                            <button className='btn btn-sm btn-success' onClick={onInvoice}>Facturar</button>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col lg={4}>
                                            <div className="form-group">
                                                <label>Descripción</label>
                                                <InputField
                                                    type={"date"}
                                                    placeholder="Feha"
                                                    value={form.date || ''}
                                                    name="date"
                                                    onChange={updateInput}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="form-group">
                                                <label>Forma de pago</label>
                                                <select className='form-control' name="payment_form_id" value={form.payment_form_id} onChange={updateInput}>
                                                    <option value={0}>Seleccione</option>
                                                    {listPaymentForm.map((a, b) => <option key={b} value={a.id}>{a.name}</option>)}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="form-group">
                                                <label>Método de Pago</label>
                                                <select className='form-control' name="payment_method_id" value={form.payment_method_id} onChange={updateInput}>
                                                    <option value={0}>Seleccione</option>
                                                    {listPaymentMethod.map((a, b) => <option key={b} value={a.id}>{a.name}</option>)}
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={4}>
                                            <div className="form-group">
                                                <label>Nota</label>
                                                <textarea className='form-control' name='notes' onChange={updateInput} placeholder='Notas'></textarea>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer>
                                    <Row>
                                        <Col><h4>TOTAL A FACTURAR</h4></Col>
                                        <Col className='text-right'>
                                            <h4>{formatNumber(listFiltered.filter(d => d.checked === true).reduce((a, b) => a + parseFloat(b.total), 0))}</h4>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { fe, client } = props

    return {
        fetching_data_create: fe.fetching_data_create,
        data_create: fe.data_create,
        fetching_client: client.fetching_client,
        data_clients: client.clients,
        fetching_order: fe.fetching_order,
        data_order: fe.data_order,
        fetching_create_invoice: fe.fetching_create_invoice
    }
}
export default connect(mapsState, { onCreateInvoice, getDataCreate, getAllClients, getListOrders, toogleAlert })(FECreatePage);