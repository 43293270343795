
import { getHeaders } from '../utils'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_crud: 0,
    data: [],
    row: {},
    errors: {},
}


const OTHER_REVENUES = "OTHER_REVENUES"
const OTHER_REVENUES_SUCCESS = "OTHER_REVENUES_SUCCESS"

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case OTHER_REVENUES:
            return { ...state, fetching_list: 1 }
        case OTHER_REVENUES_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        default:
            return state;
    }
}

export let getOtherRevenues = (form) => (dispatch, getState) => {
    dispatch({
        type: OTHER_REVENUES,
    });

    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
    let branch = JSON.parse(localStorage.getItem("branch"));

    let url = `${API_URL}/api/cost/revenue?date_init=${date_init}&date_end=${date_end}&branch_id=${branch.id}`;

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: OTHER_REVENUES_SUCCESS,
            payload: resp.results
        })

    })
}