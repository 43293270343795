import React, { useEffect, useRef, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import TemplateMenu from '../../template/menu.template'

import { getReviewBank } from '../../Redux/reportDuck'
import { connect } from 'react-redux';
import moment from 'moment';
import { formatNumber } from '../../utils';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { toogleAlert } from '../../Redux/alertDuck'

const ReviewBankPage = ({ getReviewBank, fetching_bank, data, toogleAlert }) => {

    let { method_payment_id } = useParams()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const turn_id = searchParams.get('turn_id');
    const date_init = searchParams.get('date_init');
    const date_end = searchParams.get('date_end');
    const [list, setList] = useState([])
    const tableRef = useRef(null);


    useEffect(() => {
        toogleAlert(true)
        getReviewBank({ date_init, date_end, method_payment_id, turn_id })
    }, [date_init, turn_id])

    useEffect(() => {
        if (fetching_bank === 2) {
            setList(data);
            toogleAlert(false)
        }
    }, [fetching_bank])

    return (
        <TemplateMenu>
            <Row>
                <Col><h4>Reporte de Bancos <DownloadTableExcel
                    filename="reporte_bancos"
                    sheet="bancos"
                    currentTableRef={tableRef.current}>
                    <RiFileExcel2Fill color='green' className='cursor-pointer' />
                </DownloadTableExcel> </h4> </Col>
            </Row>
            <Row>
                <Col>
                    <Table className='table table-sm table-bordered' ref={tableRef}>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Order</th>
                                <th>Turno</th>
                                <th>Fecha</th>
                                <th>Cliente</th>
                                <th>Plate</th>
                                <th>Método de Pago</th>
                                <th>Categoria</th>
                                <th className='text-center'>Propina</th>
                                <th className='text-center'>Monto</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((d, k) => <tr key={k}>
                                <td>{d.id}</td>
                                <td>{d.cashier_id}</td>
                                <td>{moment(d.finish_at).format("YYYY-MM-DD hh:mm A")}</td>
                                <td>{d.client}</td>
                                <td>{d.plate}</td>
                                <td>{d.method_payment}</td>
                                <td>{d.category}</td>
                                <td align='right'>{formatNumber(d.tip)}</td>
                                <td align='right'>{formatNumber(d.amount)}</td>
                            </tr>)}

                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={8}>PROPINA</th>
                                <th className='text-right'>{formatNumber(list.reduce((a, b) => a + parseFloat(b.tip), 0))}</th>
                            </tr>
                            <tr>
                                <th colSpan={8}>SUBTOTAL</th>
                                <th className='text-right'>{formatNumber(list.reduce((a, b) => a + parseFloat(b.amount), 0))}</th>
                            </tr>
                            <tr>
                                <th colSpan={8}>TOTAL</th>
                                <th className='text-right'>{formatNumber(list.reduce((a, b) => a + (parseFloat(b.amount) + parseFloat(b.tip)), 0))}</th>
                            </tr>

                        </tfoot>
                    </Table>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { report } = props

    return {
        fetching_bank: report.fetching_bank,
        data: report.data_bank,
    }
}

export default connect(mapsState, { getReviewBank, toogleAlert })(ReviewBankPage);