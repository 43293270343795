import React from 'react'
import { Link } from 'react-router-dom';
import { MdInsertPhoto, MdPictureAsPdf } from "react-icons/md";
import { formatNumber } from '../../utils';
import { Col, Row } from 'react-bootstrap';
const API_URL = process.env.REACT_APP_API_URL

const ItemTable = ({ item, onShowPhotos, index, onPayment }) => {
    let { client, category, payment, detail, payments } = item
    let pay = payment !== null ? payment.description : ''
    let images = item.photos.length > 0 ? <MdInsertPhoto size={20} className="cursor-pointer" onClick={() => onShowPhotos(item.photos)} /> : ''
    let total = detail.reduce((a, b) => a + parseFloat(b.price), 0)
    let total_to_pay = detail.reduce((a, b) => a + (parseFloat(b.price) * parseFloat(b.percent / 100)), 0)

    let pdf = item.status_id !== 2 && <a href={`${API_URL}/api/orders-view-pdf/${item.id}`} target="_blank"><MdPictureAsPdf size={25} /></a>

    return (
        <tr className={`${item.payments.filter(d => d.method_payment_id === 8 || d.method_payment_id === 40).length > 0 ? 'table-warning' : ''} ${item.status_id === 5 ? 'table-danger' : ''}`}>
            <td>{index}</td>
            <td>{item.updated_at_text}</td>
            <td>{item.id}</td>
            <td>{item.day}</td>
            <td>{client.name}</td>
            <td>{client.phone}</td>
            <td>{item.detail.length}</td>
            <td>{category.title}</td>
            <td>{item.plate}</td>
            <td>{payments.map((d, k) => <p key={k}>{d.payment.description} ({formatNumber(d.amount)})</p>)}</td>
            <td>{images}</td>
            <td align='right'>{formatNumber(item.discount)}</td>
            <td align='right'>{formatNumber(item.total)}</td>
            <td align='right'>{item.total_product_formated}</td>
            <td align='right'>{item.total_formated}</td>
            <td align='right'>{formatNumber(parseFloat(item.tax))}</td>
            <td align='right'>{formatNumber(parseFloat(item.tip))}</td>
            <td align='right'>{formatNumber(parseFloat(item.total_payment_worker) + parseFloat(item.tip))}</td>
            <td>{item.comment_cancel}</td>
            <td>{item.operators.map(d => d.user.name).join(",")}</td>
            <td>{item.status}</td>
            <td>
                {item.payments.filter(d => d.method_payment_id === 8 || d.method_payment_id === 40).length > 0 && <>
                    <button className='btn btn-sm btn-success' onClick={() => onPayment(item)}>Pagar</button>
                </>}
                <Link className='btn btn-sm btn-info ml-3' to={`/ordenes/${item.id} `} target="_blank">Ver</Link>
                {pdf}
            </td>
        </tr>
    )
}

export default ItemTable;