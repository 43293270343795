
import axios from 'axios'
import { getHeaders } from '../utils'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_crud: 0,
    data: [],
    row: {},
    errors: {},
}


const TAX = "TAX"
const TAX_SUCCESS = "TAX_SUCCESS"

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case TAX:
            return { ...state, fetching_list: 1 }
        case TAX_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        default:
            return state;
    }
}

export let getTaxes = () => (dispatch, getState) => {
    dispatch({
        type: TAX
    })

    if (getState().user.loggedIn) {
        axios.get(`${API_URL}/api/tax`, {
            headers: getHeaders()

        }).then(resp => {
            dispatch({
                type: TAX_SUCCESS,
                payload: resp.data.results
            });

        }).catch(error => {
            console.log('error', error)
            let { status } = error.response;
        });
    } else {
        console.log("no Ingreso");
    }
}