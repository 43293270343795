import React, { useEffect, useRef, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import TemplateMenu from '../../template/menu.template'
import ItemTableExpense from './item.table.expense';
import { getListCost, onCreateExpense, onDeleteExpense, onEditExpense } from '../../Redux/expenseDuck';
import { getParameterBySegment } from '../../Redux/parameterDuck';
import { getTurns } from '../../Redux/turnDuck';
import { getOperators } from '../../Redux/userDuck';

import moment from 'moment';
import ModalExpense from './modal.expense';
import ModalPhoto from './modal.photo';
import { formatNumber } from '../../utils';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from 'react-icons/ri';

const ExpensePage = ({ getListCost, fetching_list, data, total, getParameterBySegment, fetching_segment, data_segment,
    onCreateExpense, errors, fetching_create, onDeleteExpense, onEditExpense, segment, turn, getTurns,
    fetching_list_turn, data_turn, getOperators, fetching_operator, data_operator }) => {
    const [list, setList] = useState([])
    const [listExpense, setListExpense] = useState([])
    const [listPayment, setListPayment] = useState([])
    const [form, setForm] = useState({
        date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD"), status_id: 3,
        method_payment_id: 0, concept_id: 0, deleted: false
    })
    const [modal, setModal] = useState(false)
    const [modalPhoto, setModalPhoto] = useState(false)
    const [photo, setPhoto] = useState(null)
    const [listTurn, setListTurn] = useState([])
    const [listOperator, setListOperator] = useState([])
    const tableRef = useRef(null);



    useEffect(() => {
        if (fetching_list === 2) {
            setList(data);
        }
    }, [fetching_list])

    useEffect(() => {

        if (fetching_list_turn === 2) {
            setListTurn(data_turn)
        }
    }, [fetching_list_turn])

    useEffect(() => {
        if (fetching_operator === 2) {
            setListOperator(data_operator.filter(d => d.is_payroll))
        }
    }, [fetching_operator])

    useEffect(() => {
        if (fetching_segment === 2) {
            if (segment === 'box') {
                setListExpense(data_segment);
                getParameterBySegment("payment")
            }

            if (segment === 'payment') {
                setListPayment(data_segment)
            }
        }
    }, [fetching_segment])

    useEffect(() => {
        if (fetching_create === 2) {
            setModal(false)
            getListCost()
        }
    }, [fetching_create])


    useEffect(() => {
        getListCost()
        getParameterBySegment("box")
        getTurns()
        getOperators()
    }, [])

    const updateField = (e) => {

        if (e.target.type === 'checkbox') {
            setForm({
                ...form,
                [e.target.name]: e.target.checked
            })
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value
            })
        }

    }

    const onFilter = () => {
        getListCost(form)
    }
    const onDelete = (item) => {
        if (window.confirm("¿Realmente deseas Borrar Este Registro?")) {
            onDeleteExpense(item.id)
        }
    }

    const onShowPhoto = (photo) => {

        console.log('photo', photo);

        setModalPhoto(true)
        setPhoto(photo)
    }



    return (
        <TemplateMenu>
            <Row>
                <Col lg={3} className="d-flex align-items-center"><h4>Gastos Operativos</h4>
                    <button className='btn btn-sm btn-info ml-4' onClick={() => setModal(true)}>Agregar</button>
                </Col>
            </Row>
            <Row className='my-2'>
                <Col>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Método de Pago</label>
                                <select className='form-control' name="method_payment_id" onChange={updateField}>
                                    <option value={0}>Todos</option>
                                    {listPayment.map((v, k) => <option value={v.id} key={k}>{v.description}</option>)}
                                </select>
                                {errors.method_payment_id !== undefined && (<label className="error-label">{errors.method_payment_id}</label>)}

                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Concepto</label>
                                <select className='form-control' name="concept_id" onChange={updateField}>
                                    <option value={0}>Todos</option>
                                    {listExpense.map((v, k) => <option value={v.id} key={k}>{v.description}</option>)}
                                </select>
                                {errors.method_payment_id !== undefined && (<label className="error-label">{errors.method_payment_id}</label>)}

                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Fecha de Inicio</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={form.date_init}
                                    name="date_init"
                                    onChange={updateField}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Fecha Fin</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={form.date_end}
                                    name="date_end"
                                    onChange={updateField}
                                />
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="form-group">
                                <label>Turnos</label>
                                <select className='form-control' name='turn_id' value={form.turn_id} onChange={updateField}>
                                    <option value={0}>Seleccione</option>
                                    {listTurn.map((d, k) => <option key={k} value={d.id}>{d.user.name} | {d.date_start} | {d.date_end}</option>)}
                                </select>
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Borrados?</label>
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    value={form.deleted}
                                    name="deleted"
                                    onChange={updateField}
                                />
                            </div>
                        </Col>

                    </Row>

                </Col>
                <Col lg={2} className="d-flex align-items-center">
                    <button className='btn btn-sm btn-warning' onClick={onFilter}>Filtrar</button>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Col lg={12}>
                    <Row>
                        <Col>
                            <DownloadTableExcel
                                filename="gastos"
                                sheet="gastos"
                                currentTableRef={tableRef.current}>
                                <RiFileExcel2Fill color='green' size={30} className='cursor-pointer' />
                                {/* <button className='btn btn-sm btn-info'> Export excel </button> */}
                            </DownloadTableExcel>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table className='table-sm table-bordered table-hover' ref={tableRef} responsive>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Concepto</th>
                                        <th>Descripción</th>
                                        <th>Método de Pago</th>
                                        <th>Soporte</th>
                                        <th className='text-center'>Valor</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(list.length > 0) ? list.map((l, k) => <ItemTableExpense key={k} item={l}
                                        onDelete={onDelete}
                                        onEdit={onEditExpense}
                                        onShowPhoto={onShowPhoto} />) : <tr><td colSpan={6}>No hay registros</td></tr>}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={5}><b>TOTAL</b></td>
                                        <td align='right'><b>{formatNumber(list.reduce((a, b) => a + parseFloat(b.value), 0))}</b></td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>

                </Col>
            </Row>
            <ModalExpense
                listTurn={listTurn}
                show={modal}
                listExpense={listExpense}
                listPayment={listPayment}
                list_operators={listOperator}
                onSuccess={onCreateExpense}
                errors={errors}
                onHide={() => setModal(false)}
                turn={turn}
            />
            <ModalPhoto show={modalPhoto} photo={photo} onHide={() => setModalPhoto(false)} />

        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { expense, parameter, turn, user } = props

    return {
        fetching_list: expense.fetching_list,
        data: expense.data,
        total: expense.total,
        errors: expense.errors,
        fetching_create: expense.fetching_create,
        fetching_segment: parameter.fetching_segment,
        data_segment: parameter.data_segment,
        segment: parameter.segment,
        turn: turn.row,
        fetching_list_turn: turn.fetching_list,
        data_turn: turn.data,
        fetching_operator: user.fetching_operator,
        data_operator: user.data_operator,
    }
}

export default connect(mapsState, {
    getListCost, getParameterBySegment, onCreateExpense, onDeleteExpense,
    onEditExpense, getTurns, getOperators
})(ExpensePage);