import React, { useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';
import SelectField from '../Components/Widgets/SelectField';

const ModalParkingPrice = ({ show, onHide, onCreate, onEdit }) => {

    const [form, setForm] = useState({ category_type: '', price: '' })

    const listPlan = [
        { id: "MINUTOS", description: "MINUTOS" },
        { id: "PLENA", description: "PLENA" },
        { id: "MENSUAL", description: "MENSUAL" },
        { id: "NOCHE", description: "NOCHE" },
        { id: "HORA", description: "HORA" },
        { id: "QUINCENAL", description: "QUINCENAL" },
        { id: "DIAS", description: "DIAS" },
    ]

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = () => {
        if (form.id === undefined) {
            onCreate(form)
        } else {
            onEdit(form)
        }

    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Precio</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='my-2'>
                    <Col>
                        <SelectField name='category_type'
                            label={"TIPO DE TARIFA"}
                            value={form.category_type}
                            onChange={updateInput}
                            data={listPlan}
                            option={"description"}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Precio</label>
                            <InputField
                                type="number"
                                placeholder="price"
                                value={form.price}
                                name="price"
                                onChange={updateInput}
                            // error={errors.price}
                            />
                        </div>
                    </Col>

                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col><button className='btn btn-sm btn-success' onClick={onSubmit}>Guardar</button></Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalParkingPrice;