import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';
import { connect } from 'react-redux';
import { createUser, updateUser } from '../../Redux/userDuck'
import { getBranchOfficeByBusiness } from '../../Redux/branch.officeDuck'
import { getAllBusiness } from '../../Redux/businessDuck'
import BtnOrangeSm from '../Components/Widgets/BtnOrangeSm'
import BtnBlueModal from '../Components/Widgets/BtnBlueModal'
import BlueTitleBack from '../Components/Widgets/BlueTitleBack'

const ModalUsers = ({ showModal, onHide, errors, formEdit, fetching, createUser, updateUser, onSuccess, getAllBusiness,
    business, getBranchOfficeByBusiness, data_branch, rols, user_data }) => {
    const initState = {
        name: '', email: '', phone: '', password: '', confirmation: '', role_id: 0, business_id: 0, status: true,
        branch_id: 0, percent: 0, salary: 0, bonification: 0, eps_id: 0, pension_id: 0, is_payroll: false,
        type_schedule: 1
    };

    const [form, setForm] = useState(initState)


    useEffect(() => {
        if (fetching === 2) {
            setForm(initState)
            onHide()
            onSuccess()
        }
    }, [fetching])

    useEffect(() => {

        if (Object.keys(formEdit).length > 0) {
            getBranchOfficeByBusiness(formEdit.business_id);
            setForm(formEdit);
        } else {
            if (fetching !== 3 && fetching !== 2) {
                setForm(initState)
            }
        }

    }, [formEdit])

    useEffect(() => {
        getAllBusiness()
    }, [])

    const closeModalTmp = () => {
        onHide()
    }

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const updateInputClient = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
        getBranchOfficeByBusiness(e.target.value)
    }

    const updateInputClientSelect = (e) => {

        setForm({
            ...form,
            [e.target.name]: parseInt(e.target.value)
        })
    }

    const updateCheck = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    }

    const onSubmit = () => {

        if (form.id !== undefined) {
            updateUser(form)
        } else {
            createUser(form)
        }
    }

    const printSelect = (item, i) => {
        return (
            <option key={i} value={item.id}>{item.business} ({item.document})</option>
        )
    }

    const printSelectBranch = (item, i) => {
        return (
            <option key={i} value={item.id}>{item.description}</option>
        )
    }
    const printSelectRols = (item, i) => {
        return (
            <option key={i} value={item.id}>{item.title}</option>
        )
    }

    return (
        <Modal show={showModal} onHide={closeModalTmp} size="lg">
            <div className="modal-header border-bottom-custom">
                <BlueTitleBack title='USUARIOS' />
                <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={closeModalTmp}>
                    {/* <img src={close} className="size-icon-35" /> */}
                </button>
            </div>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Nombres</label>
                                <InputField
                                    placeholder="Agrega los nombres"
                                    value={form.name || ''}
                                    name="name"
                                    onChange={updateInput}
                                    error={errors.name}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Email</label>
                                <InputField
                                    placeholder="Agrega email"
                                    value={form.email || ''}
                                    name="email"
                                    onChange={updateInput}
                                    error={errors.email}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Rol</label>
                                <select className="form-control" name="role_id" value={form.role_id} onChange={updateInput}>
                                    <option value="0">Seleccione</option>
                                    {rols.map(printSelectRols)}
                                </select>
                                {errors !== undefined ? (<label className="error-label">{errors.role_id}</label>) : null}

                            </div>
                        </Col>
                    </Row>
                    <Row>

                        <Col>
                            <div className="form-group">
                                <label>Salario</label>
                                <InputField
                                    placeholder="Agrega Salario"
                                    value={form.salary}
                                    name="salary"
                                    onChange={updateInput}
                                    error={errors.salary}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Bonificación</label>
                                <InputField
                                    placeholder="Agrega Salario"
                                    value={form.bonification}
                                    name="bonification"
                                    onChange={updateInput}
                                    error={errors.bonification}
                                />
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12} lg={6}>
                            <div className="form-group">
                                <label>Clave</label>
                                <InputField
                                    type="password"
                                    placeholder="Agrega la Clave"
                                    value={form.password || ''}
                                    name="password"
                                    onChange={updateInput}
                                    error={errors.password}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Confirmación</label>
                                <InputField
                                    type="password"
                                    placeholder="Confirmación de la clave"
                                    value={form.confirmation || ''}
                                    name="confirmation"
                                    onChange={updateInput}
                                    error={errors.confirmation}
                                />
                            </div>
                        </Col>
                    </Row>

                    {
                        user_data.role_id === 1 && <>


                            <Row>
                                <Col xs={12} lg={6}>
                                    <div className="form-group">
                                        <label>Cliente</label>
                                        <select className="form-control" name="business_id" value={form.business_id} onChange={updateInputClient}>
                                            <option value="0">Selecciona un Cliente</option>
                                            {business.map(printSelect)}
                                        </select>
                                        {errors !== undefined ? (<label className="error-label">{errors.business_id}</label>) : null}
                                    </div>
                                </Col>
                                {/* <Col>
                                    <div className="form-group">
                                        <label>Sucursal</label>
                                        <select className="form-control" name="branch_id" value={form.branch_id} onChange={updateInputClientSelect}>
                                            <option value="0">Selecciona una Sucursal</option>
                                            {(data_branch.length > 0) ? data_branch.map(printSelectBranch) : ''}
                                        </select>
                                        {errors !== undefined ? (<label className="error-label">{errors.branch_id}</label>) : null}

                                    </div>
                                </Col> */}
                                <Col >
                                    <div className="form-group">
                                        <label>Porcentaje</label>
                                        <InputField
                                            placeholder="porcentaje"
                                            value={form.percent || ''}
                                            name="percent"
                                            onChange={updateInput}
                                            error={errors.percent}
                                        />
                                    </div>
                                </Col>

                            </Row>
                        </>
                    }


                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Celular</label>
                                <InputField
                                    type="number"
                                    placeholder="Agrega el Celular"
                                    value={form.phone || ''}
                                    name="phone"
                                    onChange={updateInput}
                                    error={errors.phone}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Liquidación Especial</label>
                                <select className="form-control" name="business_id" value={form.type_schedule} onChange={updateInputClient}>
                                    <option value="0">Selecciona una Liquidación</option>
                                    <option value="1">Unido al horario de la Empresa</option>
                                    <option value="2">Dia</option>
                                    <option value="3">Noche</option>
                                </select>
                                {errors !== undefined ? (<label className="error-label">{errors.type_schedule}</label>) : null}
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Activar para Nomina</label>
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    checked={form.is_payroll || false}
                                    name="is_payroll"
                                    onChange={updateCheck}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Estado</label>
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    checked={form.status || false}
                                    name="status"
                                    onChange={updateCheck}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Row className="justify-content-center">
                    <Col>
                        <button className='btn btn-success btn-sm' onClick={onSubmit}>
                            Guardar
                        </button>

                    </Col>
                    <Col>
                        <button className='btn btn-danger btn-sm' onClick={closeModalTmp}>
                            Cerrar
                        </button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

const mapsState = (props) => {
    let { user, business, rol, branch_office } = props
    return {
        errors: user.errors,
        selected: user.selected,
        fetching: user.fetching_create,
        fetching: user.fetching_update,
        user_data: user.data,
        business: business.data,
        rols: rol.data,
        data_branch: branch_office.data_branch,
    }
}

export default connect(mapsState, { createUser, updateUser, getAllBusiness, getBranchOfficeByBusiness })(ModalUsers);