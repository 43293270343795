import axios from 'axios'
import { getHeaders, validateBranch, validateInput } from '../utils';
import { logOutAction } from "./userDuck";

const API_URL = process.env.REACT_APP_API_URL
let initialState = {
    fetching_list: 0,
    fetching_create: 0,
    fetching_branch: 0,
    data: [],
    data_branch: [],
    selected: {},
    errors: {},
    fetching_delete: 0
}

const LIST_BRANCH = "LIST_BRANCH"
const LIST_BRANCH_SUCCESS = "LIST_BRANCH_SUCCESS"
const LIST_BRANCH_ERROR = "LIST_BRANCH_ERROR"

const CREATE_BRANCH = "CREATE_BRANCH"
const CREATE_BRANCH_SUCCESS = "CREATE_BRANCH_SUCCESS"

const UPDATE_BRANCH = "UPDATE_BRANCH"
const UPDATE_BRANCH_SUCCESS = "UPDATE_BRANCH_SUCCESS"
const BRANCH_ERROR = "BRANCH_ERROR"

const LIST_BRANCH_BUSINESS = "LIST_BRANCH_BUSINESS"
const LIST_BRANCH_BUSINESS_SUCCESS = "LIST_BRANCH_BUSINESS_SUCCESS"


export default function reducer(state = initialState, action) {
    let clients;
    switch (action.type) {
        case LIST_BRANCH:
            return { ...state, fetching_list: 1, fetching_create: 0 }
        case LIST_BRANCH_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload, branch: action.branch }
        case CREATE_BRANCH:
            return { ...state, fetching_create: 1 }
        case CREATE_BRANCH_SUCCESS:
            return { ...state, fetching_create: 2 }
        case BRANCH_ERROR:
            return { ...state, fetching_create: 3, errors: action.payload }
        case UPDATE_BRANCH:
            return { ...state, fetching_create: 1 }
        case UPDATE_BRANCH_SUCCESS:
            return { ...state, fetching_create: 2 }
        case LIST_BRANCH_BUSINESS:
            return { ...state, fetching_branch: 1 }
        case LIST_BRANCH_BUSINESS_SUCCESS:
            return { ...state, fetching_branch: 2, data_branch: action.payload }
        case LIST_BRANCH_ERROR:
            return { ...state, fetching_list: 3 }

        default:
            return state;
    }
}

export let getBranchOfficeByBusiness = (business_id) => (dispatch, getState) => {
    dispatch({
        type: LIST_BRANCH_BUSINESS
    })


    axios.get(`${API_URL}/api/branch-office-business/${business_id}`, {
        headers: getHeaders()
    })
        .then(response => {

            dispatch({
                type: LIST_BRANCH_BUSINESS_SUCCESS,
                payload: response.data.results,
            })
        })
        .catch(error => {
            // console.log('error', error)
            // let { status } = error.response;

            // if (status === 401) {
            //     logOutAction()(dispatch, getState)
            // }

        });
}

export let getAllBranchOffice = (page) => (dispatch, getState) => {
    dispatch({
        type: LIST_BRANCH
    })

    let { loggedIn } = getState().user

    page = page || getState().user.current_page

    let list_branch = JSON.parse(localStorage.getItem("list_branch"))
    let branch = JSON.parse(localStorage.getItem("branch"))


    if (list_branch !== null) {

        list_branch = list_branch.map(b => {
            if (b.id === branch.id) {
                b.selected = true
            } else {
                b.selected = false
            }
            return b;
        })

        dispatch({
            type: LIST_BRANCH_SUCCESS,
            payload: list_branch,
            branch: branch
        })
    } else {
        axios.get(`${API_URL}/api/branch-office`, {
            headers: getHeaders()
        })
            .then(response => {

                localStorage.setItem("branch", JSON.stringify(response.data.results[0]))
                localStorage.setItem("list_branch", JSON.stringify(response.data.results))
                dispatch({
                    type: LIST_BRANCH_SUCCESS,
                    payload: response.data.results,
                    branch: response.data.results[0]
                })
            })
            .catch(error => {
                dispatch({
                    type: LIST_BRANCH_ERROR,
                });
            });
    }

}

export let updateBranch = (form) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_BRANCH
    })
    let rules = [
        { field: "description", msg: "La descripción es requerida" },
        { field: "address", msg: "La Dirección es requerida" },
        { field: "phone", msg: "El telefono es requerido" },
        { field: "department_id", msg: "El Departamento es requerido" },
        { field: "city_id", msg: "La ciudad es requerida" },
    ]

    console.log('form', form);


    let validate = validateInput(form, rules);

    if (validate !== null) {
        dispatch({
            type: BRANCH_ERROR,
            payload: validate
        })
        return;
    }

    axios.put(`${API_URL}/api/branch-office/${form.id}`, form, {
        headers: getHeaders()
    })
        .then(response => {
            dispatch({
                type: UPDATE_BRANCH_SUCCESS
            })
        })
        .catch(error => {
            // let { status } = error.response;

            // if (status === 401) {
            //     logOutAction()(dispatch, getState)
            // }
            // console.log("status", status)
        });

}

export let createBranch = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_BRANCH
    })

    let rules = [
        { field: "description", msg: "La descripción es requerida" },
        { field: "address", msg: "La Dirección es requerida" },
        { field: "phone", msg: "El telefono es requerido" },
        { field: "department_id", msg: "El Departamento es requerido" },
        { field: "city_id", msg: "La ciudad es requerida" },
    ]

    let validate = validateInput(form, rules);

    if (validate !== null) {
        dispatch({
            type: BRANCH_ERROR,
            payload: validate
        })
        return;
    }

    let url = `${API_URL}/api/branch-office`

    axios.post(url, form, {
        headers: getHeaders()
    })
        .then(response => {
            dispatch({
                type: CREATE_BRANCH_SUCCESS
            })
        })
        .catch(error => {
            let { status } = error.response;

            if (status === 401) {
                logOutAction()(dispatch, getState)
            }
            console.log("status", status)
        });

}