import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import TemplateMenu from '../../template/menu.template'
import { getClientById, onEditClient } from '../../Redux/clientDuck'
import { getCategories } from '../../Redux/categoryDuck'

import { connect } from 'react-redux';
import SpecialPrice from './special.price';
import VehiclesPage from './assoc_vehicles.page';

const EditClientPage = ({ getClientById, getCategories, row, fetching_by_id, onEditClient, fetching_create, fetching_edit, fetching_category, data_category, fetching_vehicles }) => {
    let { id } = useParams();
    const [form, setForm] = useState({
        name: '', phone: '', document: '', service_client: [], has_aggrement: false, recurrent: [],
        email: ''
    })

    const [listCategory, setListCategory] = useState([])
    const history = useHistory()

    useEffect(() => {
        if (fetching_category === 2) {
            setListCategory(data_category)
        }
    }, [fetching_category])

    useEffect(() => {
        if (fetching_by_id === 2) {
            getCategories()
            setForm(row)
        }
    }, [fetching_by_id])

    useEffect(() => {
        if (fetching_vehicles === 2) {
            getClientById(id)
        }
    }, [fetching_vehicles])

    useEffect(() => {
        if (fetching_create === 2) {
            history.goBack()
        }
    }, [fetching_create])

    useEffect(() => {
        if (fetching_edit === 2) {
            getClientById(id)

        }
    }, [fetching_edit])

    useEffect(() => {
        getClientById(id)

    }, [id])

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }
    const updateCheck = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    }

    const onSaveClient = () => {
        onEditClient(form)
    }

    return (
        <TemplateMenu>
            <Row className='mt-2 d-flex justify-content-center'>
                <Col lg={4}>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col>
                                    <h4>Información del Cliente</h4>
                                </Col>
                                <Col lg={2}>
                                    <button className='btn btn-sm btn-success' onClick={onSaveClient}>Guardar</button>

                                </Col>
                            </Row>

                        </Card.Header>
                        <Card.Body>
                            <Row className='my-1'>
                                <Col lg={5}><b>Nombre</b></Col>
                                <Col lg={7}>
                                    <input className='form-control' name="name" value={form.name} onChange={updateInput} />
                                </Col>
                            </Row>
                            <Row className='my-1'>
                                <Col lg={5}><b>Telefono</b></Col>
                                <Col lg={7}>
                                    <input className='form-control' name="phone" value={form.phone} onChange={updateInput} />
                                </Col>
                            </Row>
                            <Row className='my-1'>
                                <Col lg={5}><b>Documento</b></Col>
                                <Col lg={7}>
                                    <input className='form-control' name="document" value={form.document} onChange={updateInput} />
                                </Col>
                            </Row>
                            <Row className='my-1'>
                                <Col lg={5}><b>Email</b></Col>
                                <Col lg={7}>
                                    <input type="email" className='form-control' name="email" value={form.email} onChange={updateInput} />
                                </Col>
                            </Row>
                            <Row className='my-1'>
                                <Col lg={5}><b>¿Tiene Convenio?</b></Col>
                                <Col lg={7}>
                                    <input className='form-control' type={"checkbox"} name="has_aggrement" checked={form.has_aggrement} onChange={updateCheck} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col>
                    <Tabs
                        defaultActiveKey="profile"
                        id="uncontrolled-tab-example"
                        className="mb-3">
                        <Tab eventKey="profile" title="Precios Preferenciales">
                            <SpecialPrice client_id={id} listServices={form.service_client} listCategory={listCategory} />
                        </Tab>
                        <Tab eventKey="plates" title="Vehículos Asociados">
                            <VehiclesPage client_id={id} vehicles={form.recurrent} listCategory={listCategory} />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { client, service, category } = props

    return {
        fetching_by_id: client.fetching_by_id,
        fetching_create: client.fetching_create,
        fetching_vehicles: client.fetching_vehicles,
        fetching_edit: service.fetching_edit,
        fetching_category: category.fetching,
        data_category: category.data,
        row: client.row,
    }
}

export default connect(mapsState, { getClientById, onEditClient, getCategories })(EditClientPage);
