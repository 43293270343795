import React, { Fragment } from 'react'
import './index.css'

const InputField = ({ type, name, value, placeholder, onChange, error, min, max, className, onBlur }) => {
    type = type || 'text'
    className = className || 'form-control'
    return (
        <Fragment>
            <input
                type={type}
                className={className}
                name={name}
                value={value}
                placeholder={placeholder}
                min={min}
                max={max}
                onChange={onChange}
                onBlur={onBlur}
            />
            {error !== undefined ? (<label className="error-label">{error}</label>) : null}
        </Fragment>
    )
}

export default InputField