import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getListInvoice } from '../../Redux/fetDuck'
import { formatNumber } from '../../utils';
import moment from 'moment';

const FePage = ({ getListInvoice, fetching_invoice, data_invoice }) => {
    const history = useHistory()
    const [list, setList] = useState([])

    useEffect(() => {
        if (fetching_invoice === 2) {
            setList(data_invoice)
        }
    }, [fetching_invoice])

    useEffect(() => {
        getListInvoice()
    }, [])

    const goToCreate = () => {
        history.push("/fe-create")
    }

    return (
        <TemplateMenu>
            <Row>
                <Col lg={2}><p>Facturación Electronica</p></Col>
                <Col>
                    <button className='btn btn-sm btn-info' onClick={goToCreate}>Crear</button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table className='table table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Documento Cliente</th>
                                <th>Fecha</th>
                                <th>Factura</th>
                                <th>Prefijo</th>
                                <th>Subtotal</th>
                                <th>Total</th>
                                <th>Archivos</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((d, i) => <tr key={i}>
                                <td>{d.customer}</td>
                                <td>{moment(d.date_issue).format("YYYY-MM-DD h:m A")}</td>
                                <td>{d.number}</td>
                                <td>{d.prefix}</td>
                                <td className='text-right'>{formatNumber(d.subtotal)}</td>
                                <td className='text-right'>{formatNumber(d.total)}</td>
                                <td>
                                    <button className='btn btn-sm btn-info mx-2'>PDF</button>
                                    <button className='btn btn-sm btn-danger '>XML</button>
                                </td>
                            </tr>)}

                        </tbody>
                    </Table>

                </Col>
            </Row>

        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { fe } = props

    return {
        data_invoice: fe.data_invoice,
        fetching_invoice: fe.fetching_invoice,
    }
}
export default connect(mapsState, { getListInvoice })(FePage);