import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { getBusinessById } from '../../Redux/businessDuck'
import { Col, Row } from 'react-bootstrap'
import FormBusiness from './form.business'

const EditBusinessPage = ({ getBusinessById, fetching_by_id, row }) => {

    let { id } = useParams()

    const [form, setForm] = useState({})

    useEffect(() => {
        getBusinessById(id)
    }, [id])

    useEffect(() => {
        if (fetching_by_id === 2) {
            setForm(row)
        }
    }, [fetching_by_id])

    const onSuccess = (data) => {
        console.log('data', data);

    }

    return (
        <TemplateMenu>

            <FormBusiness errors={{}} formEdit={form} title={"Editar Empresa"} onSuccess={onSuccess} />

        </TemplateMenu>

    )
}

const mapState = (props) => {
    let { business } = props
    return {
        fetching_by_id: business.fetching_by_id,
        row: business.row,
        errors: {}
    }
}

export default connect(mapState, { getBusinessById })(EditBusinessPage);