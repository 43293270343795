
import axios from 'axios'
import { getHeaders } from '../utils'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_create: 0,
    fetching_delete: 0,
    fetching_expense: 0,
    data: [],
    data_expense: [],
    row: {},
    errors: {},
}


const LIST_PAYROLL = "LIST_PAYROLL"
const LIST_PAYROLL_SUCCESS = "LIST_PAYROLL_SUCCESS"
const CREATE_PAYROLL = "CREATE_PAYROLL"
const CREATE_PAYROLL_SUCCESS = "CREATE_PAYROLL_SUCCESS"
const DELETE_PAYROLL = "DELETE_PAYROLL"
const DELETE_PAYROLL_SUCCESS = "DELETE_PAYROLL_SUCCESS"
const EXPENSE_PAYROLL = "EXPENSE_PAYROLL"
const EXPENSE_PAYROLL_SUCCESS = "EXPENSE_PAYROLL_SUCCESS"


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_PAYROLL:
            return { ...state, fetching_list: 1, fetching_create: 0 }
        case LIST_PAYROLL_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload, }
        case CREATE_PAYROLL:
            return { ...state, fetching_create: 1 }
        case CREATE_PAYROLL_SUCCESS:
            return { ...state, fetching_create: 2, data_expense: [] }
        case DELETE_PAYROLL:
            return { ...state, fetching_delete: 1 }
        case DELETE_PAYROLL_SUCCESS:
            return { ...state, fetching_delete: 2 }
        case EXPENSE_PAYROLL:
            return { ...state, fetching_expense: 1 }
        case EXPENSE_PAYROLL_SUCCESS:
            return { ...state, fetching_expense: 2, data_expense: action.payload }
        default:
            return state;
    }
}

export let getListPayroll = (form) => (dispatch, getState) => {
    dispatch({
        type: LIST_PAYROLL
    })

    if (getState().user.loggedIn) {

        console.log('form', form);


        let branch = JSON.parse(localStorage.getItem("branch"))

        axios.get(`${API_URL}/api/payroll?branch_id=${branch.id}&date_init=${form.date_init}&date_end=${form.date_end}&cashier_id=${form.turn_id}&operator_id=${form.operator_id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: LIST_PAYROLL_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            let { status, data } = error.response;
        });
    } else {
        console.log("no Ingreso");
    }
}
export let getExpenseForPayroll = (worker_id) => (dispatch, getState) => {
    dispatch({
        type: EXPENSE_PAYROLL
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))
        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))

        axios.get(`${API_URL}/api/payroll-expense?branch_id=${branch.id}&date_init=${date_init}&date_end=${date_end}&worker_id=${worker_id}`,
            {
                headers: getHeaders()
            }).then(resp => {
                dispatch({
                    type: EXPENSE_PAYROLL_SUCCESS,
                    payload: resp.data
                });

            }).catch(function (error) {
                let { status, data } = error.response;
            });
    } else {
        console.log("no Ingreso");
    }
}

export let onCreatePayroll = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_PAYROLL
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))

        form.branch_id = branch.id

        axios.post(`${API_URL}/api/payroll`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: CREATE_PAYROLL_SUCCESS,
            });

        }).catch(function (error) {
            let { status, data } = error.response;
        });
    } else {
        console.log("no Ingreso");
    }
}

export let onDeletePayroll = (id) => (dispatch, getState) => {
    dispatch({
        type: DELETE_PAYROLL
    })

    if (getState().user.loggedIn) {


        axios.delete(`${API_URL}/api/payroll/${id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: DELETE_PAYROLL_SUCCESS,
            });

        }).catch(function (error) {
            let { status, data } = error.response;
        });
    } else {
        console.log("no Ingreso");
    }
}