import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getCloseDay } from '../../Redux/reportDuck'
import { formatNumber } from '../../utils';
import { getTurns } from '../../Redux/turnDuck';
import { toogleAlert } from '../../Redux/alertDuck'

import moment from 'moment';

const CloseDayPage = ({ getCloseDay, fetching_close, data_close, getTurns, fetching_list_turn, data_turn, toogleAlert }) => {
    const [listOrder, setListOrder] = useState([])
    const [listExpense, setListExpense] = useState([])
    const [listAdvances, setListAdvances] = useState([])
    const [listInput, setListInput] = useState([])
    const [listCart, setListCart] = useState([])
    const [listParking, setListParking] = useState([])
    const [listTurn, setListTurn] = useState([])
    const [form, setForm] = useState({ turn_id: 0, date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD") })

    useEffect(() => {
        let { result_orders, result_expense, advances, inputs, result_cart, result_parking } = data_close

        if (fetching_close === 2) {
            setListOrder(result_orders)
            setListExpense(result_expense)
            setListAdvances(advances)
            setListInput(inputs)
            setListCart(result_cart)
            setListParking(result_parking)
            toogleAlert(false)
        }

    }, [fetching_close])

    useEffect(() => {
        if (fetching_list_turn === 2) {

            setListTurn(data_turn)

            let turn_active = data_turn.find(d => d.status_id === 1)

            if (turn_active !== undefined) {
                let _form = {
                    ...form,
                    turn_id: turn_active.id
                }
                setForm(_form)
                getCloseDay(_form)
            } else {
                toogleAlert(false)
            }
        }
    }, [fetching_list_turn])

    useEffect(() => {
        getTurns()
    }, [])

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSearch = () => {
        toogleAlert(true)
        getCloseDay(form)
    }

    return (
        <TemplateMenu>
            <Row className='mt-3'>
                <Col lg={3}>
                    <div className="form-group">
                        <label>Turnos</label>
                        <select className='form-control' name='turn_id' value={form.turn_id} onChange={updateField}>
                            <option value={0}>POR RANGO DE FECHA</option>
                            {listTurn.map((d, k) => <option key={k} value={d.id}>{d.user.name} - ({d.id}) | {d.date_start} | {d.date_end}</option>)}
                        </select>
                    </div>
                </Col>
                {parseInt(form.turn_id) === 0 && <>
                    <Col lg={2}>
                        <div className="form-group">
                            <label>Fecha de Inicio</label>
                            <input
                                type="date"
                                className="form-control"
                                value={form.date_init}
                                name="date_init"
                                onChange={updateField}
                            />
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div className="form-group">
                            <label>Fecha Fin</label>
                            <input
                                type="date"
                                className="form-control"
                                value={form.date_end}
                                name="date_end"
                                onChange={updateField}
                            />
                        </div>
                    </Col>
                </>}
                <Col lg={2} className='d-flex align-items-center'>
                    <button className='btn btn-sm btn-info' onClick={onSearch}>Buscar</button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <Col>Efectivo</Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row className='mt-4'>
                                <Col>Ordenes</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table className='table table-sm table-bordered'>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th></th>
                                                <th>Fecha</th>
                                                <th>Orden Id</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listOrder.map((d, i) => <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{moment(d.finish_at).format("YYYY-MM-DD hh:mm A")}</td>
                                                <td>{d.id}</td>
                                                <td align='right'>{formatNumber(d.amount)}</td>
                                            </tr>)}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th colSpan={3}>TOTAL</th>
                                                <th className='text-right'>{formatNumber(listOrder.reduce((a, b) => a + parseFloat(b.amount), 0))}</th>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row className='mt-4'>
                                <Col>GASTOS</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table className='table table-sm table-bordered'>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th>Fecha</th>
                                                <th>Categoria</th>
                                                <th>Descripción</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listExpense.map((d, i) => <tr key={i}>
                                                <td>{d.registration_date}</td>
                                                <td>{d.category}</td>
                                                <td>{d.description}</td>
                                                <td align='right'>{formatNumber(d.value)}</td>
                                            </tr>)}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th colSpan={3}>TOTAL</th>
                                                <th className='text-right'>{formatNumber(listExpense.reduce((a, b) => a + parseFloat(b.value), 0))}</th>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col>ANTICIPOS</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table className='table table-sm table-bordered'>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th>Fecha</th>
                                                <th>Descripción</th>
                                                <th>Trabajador</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listAdvances.map((d, i) => <tr key={i}>
                                                <td>{d.registration_date}</td>
                                                <td>{d.description}</td>
                                                <td>{d.name}</td>
                                                <td align='right'>{formatNumber(d.value)}</td>
                                            </tr>)}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th colSpan={3}>TOTAL</th>
                                                <th className='text-right'>{formatNumber(listAdvances.reduce((a, b) => a + parseFloat(b.value), 0))}</th>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col>INSUMO</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table className='table table-sm table-bordered'>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th>Fecha</th>
                                                <th>Descripción</th>
                                                <th>Trabajador</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listInput.map((d, i) => <tr key={i}>
                                                <td>{d.registration_date}</td>
                                                <td>{d.description}</td>
                                                <td>{d.name}</td>
                                                <td align='right'>{formatNumber(d.value)}</td>
                                            </tr>)}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th colSpan={3}>TOTAL</th>
                                                <th className='text-right'>{formatNumber(listInput.reduce((a, b) => a + parseFloat(b.value), 0))}</th>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col>TIENDA</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table className='table table-sm table-bordered'>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th>Fecha</th>
                                                <th>Cart Id</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listCart.map((d, i) => <tr key={i}>
                                                <td>{d.created_at}</td>
                                                <td>{d.id}</td>
                                                <td align='right'>{formatNumber(d.total)}</td>
                                            </tr>)}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th colSpan={2}>TOTAL</th>
                                                <th className='text-right'>{formatNumber(listCart.reduce((a, b) => a + parseFloat(b.total), 0))}</th>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col>PARQUEADERO</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table className='table table-sm table-bordered'>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th>Fecha</th>
                                                <th>Cart Id</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listParking.map((d, i) => <tr key={i}>
                                                <td>{d.created_at}</td>
                                                <td>{d.id}</td>
                                                <td align='right'>{formatNumber(d.total)}</td>
                                            </tr>)}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th colSpan={2}>TOTAL</th>
                                                <th className='text-right'>{formatNumber(listParking.reduce((a, b) => a + parseFloat(b.total), 0))}</th>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col>Bancos</Col>
                    </Row>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { report, turn } = props
    return {
        fetching_close: report.fetching_close,
        data_close: report.data_close,
        fetching_list_turn: turn.fetching_list,
        data_turn: turn.data,
    }
}

export default connect(mapsState, { getCloseDay, getTurns, toogleAlert })(CloseDayPage);