import React, { useState } from 'react';
import modify from '../../Images/modify.svg'
import { FcPlus } from "react-icons/fc";
import { Row } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


const RowTableClient = ({ item, index, onEdit, refs, onRenovate }) => {
  let { plan } = item
  let { payment } = plan || { payment: { date_end: 'Sin fecha' } }
  console.log('item', item);

  console.log('item.plan', item.plan);
  console.log('payment', payment);


  let _index = index + 1

  let reference = (_index % 20 == 0) ? refs : null

  let { addresses } = item || []
  const [selected, setSelected] = useState({})

  const [data, setData] = useState([])

  const showAddress = () => {
    if (item.id === selected.id) {
      setSelected({})
    } else {
      setSelected(item)
    }
  }

  const printAddress = (row) => {

    return (
      <tr key={item.id} className="p-0 mx-0 border-bottom active_list_reservation m-0 w-100">
        <td className="text-center border-right">{row.address}</td>
      </tr>
    )
  }

  return (
    <tr className={`${item.status !== true ? 'table-danger' : ''}`} ref={reference}>
      <td>{item.business}</td>
      <td>{item.business_name}</td>
      <td>{item.email}</td>
      <td>{item.phone}</td>
      <td>{item.document}</td>
      <td>{moment(item.end_day, "hh:mm a").format("hh:mm a")} - {moment(item.end_night, "hh:mm a").format("hh:mm a")}</td>
      <td>{item.is_trial_period ? 'Pruebas' : 'Productivo'}</td>
      <td>{item.status_text}</td>
      <td className="text-center">
        <Link className='btn btn-info btn-sm' to={`/business/${item.id}`}>Editar</Link>
        <Link className='btn btn-success btn-sm mx-2' to={`/business-license/${item.id}`}>Licencias</Link>
        <Link className='btn btn-warning btn-sm' to={`/business-fe/${item.id}`}>FE</Link>
      </td>
    </tr>
  )
}
export default RowTableClient;