import React from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const ItemCardParking = ({ item }) => {
    let { category_price } = item

    console.log('category_price', category_price);

    return (
        <Col lg={3} className='mt-2'>
            <Card>
                <Card.Header>
                    <Row>
                        <Col className='text-right'>
                            <Link className='btn btn-sm btn-info' to={`/tarifas-parqueadero/${item.id}`}>Editar</Link>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Card.Title>{item.title}</Card.Title>
                    <Row>
                        <Col>{item.description}</Col>
                    </Row>
                    <Row>
                        <Col>Estado: {item.status_text}</Col>
                    </Row>
                    <Row className='border-top mt-3'>
                        <Col><b>Precios</b></Col>
                    </Row>
                    <Table className='table table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>TIPO</th>
                                <th>PRECIO</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(category_price !== undefined && category_price.length > 0) ? category_price.map((a, k) => <tr key={k}>
                                <td>{a.category_type}</td>
                                <td align='right'>{a.price_formated}</td>
                            </tr>) : <tr><td></td></tr>}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </Col>
    )

}

export default ItemCardParking;
